import { useEffect, useState } from "react";
import ImagePreview from "../ImagePreview"
import SingleColumn from "../SingleColumn"

type Props = {
    identificationInformation?: any,
    documents?: any,
}

const MinorIdentification = ({ identificationInformation, documents }: Props) => {
    const [birthCertificate, setbirthCertificate] = useState("");

    useEffect(() => {
        const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Birth Certificate")
        setbirthCertificate(file?.file);
    }, [documents])
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Identification Details</div>
            <div className="row">
                <SingleColumn label="Birth Registration No" value={identificationInformation?.idNumber} />
                <SingleColumn label="Issuing Office" value={identificationInformation?.issuedOffice} />
                <SingleColumn label="Issued Date(BS)" value={identificationInformation?.issuedDateBs} />
                <SingleColumn label="Issued Date(AD)" value={identificationInformation?.issuedDateAd} />
                <div className="col-12">
                    <div className="row">
                        <div className='mb-3 col-12 col-md-6 col-lg-4'>
                            <ImagePreview imageSrc={birthCertificate} label="Birth Certificate:" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default MinorIdentification