// import { ParamsModel } from "../Model/index";
import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {NewMakerListState} from './reducer'

export const actions = {
  getAllNewMakerList: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_NEW_MAKER_LIST_START,
      payload: {params},
    }
  },

  getAllNewMakerListSuccess: (data: NewMakerListState) => ({
    type: actionTypes.GET_NEW_MAKER_LIST_SUCCESS,
    payload: data,
  }),

  getAllNewMakerListError: (data: NewMakerListState) => ({
    type: actionTypes.GET_NEW_MAKER_LIST_FINISH,
    payload: data,
  }),

  // get log
  getAllLog: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_LOG_START,
      payload: {params},
    }
  },
  getAllLogSuccess: (data: any) => ({
    type: actionTypes.GET_LOG_SUCCESS,
    payload: data,
  }),
  getAllLogError: (data: any) => ({
    type: actionTypes.GET_LOG_FINISH,
    payload: data,
  }),

  //export file
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_MAKER_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_MAKER_FILE_SUCCESS,
    payload: data,
  }),

  exportFileError: () => ({
    type: actionTypes.EXPORT_MAKER_FILE_FINISH,
  }),

  //add Remarks
  addRemarks: (data: any) => ({
    type: actionTypes.ADD_REMARKS_REQUEST,
    payload: data,
  }),

  addRemarksSuccess: (task: any) => ({
    type: actionTypes.ADD_REMARKS_SUCCESS,
    payload: task,
  }),

  addRemarksFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_REMARKS_FINISH,
    payload: errorMsg,
  }),

  addRemarksReset: () => ({
    type: actionTypes.ADD_REMARKS_RESET,
  }),
}
