import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
// import { UserModel } from "src/app/modules/auth/models/UserModel";
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
// import { RootState } from "src/setup";
import {GrPowerReset, GrView} from 'react-icons/gr'
import Table from 'rsuite/Table'
import {Pagination, Whisper, Tooltip, IconButton} from 'rsuite'
// import * as preliminaryVerifiedRedux from "src/app/modules/onlineAccount/preliminaryVerified/redux";
import * as fdRequestUnverifiedRedux from 'src/app/modules/fdRequest/fdRequestUnverified/redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
const Cell = Table.Cell

const FdRequestUnverified = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  const [params, setParams] = useState<StateParamsModel>({
    page: 5,
    limit: 10,
  })
  const fdUnverifiedData = useState((state: any) => {
    return state?.unverifiedData
  })
  const preliminaryVerifiedData: fdRequestUnverifiedRedux.FDRequestUnverifiedState = useSelector(
    (state: any) => {
      return state?.fdRequestUnverified
    }
  )

  const {loading, resendOtpSuccess} = preliminaryVerifiedData

  const data =
    preliminaryVerifiedData?.data?.customerDetail?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      customerId: item?.customerId,
      firstName: item?.firstName,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleGetAllData = () => {
    setParams({...params, limit: preliminaryVerifiedData?.data?.meta?.total})
    dispatch(fdRequestUnverifiedRedux.actions.getAllFDRequestUnverified(params))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              history.push({
                pathname: `/fd-request/fd-request-unverified/${rowData.id}`,
                state: rowData,
              })
            }}
            icon={<GrView size={18} />}
          />
        </Whisper>
      </Cell>
    )
  }

  useEffect(() => {
    dispatch(fdRequestUnverifiedRedux.actions.getAllFDRequestUnverified(params))
  }, [params])

  useEffect(() => {
    if (resendOtpSuccess) {
      toast.success('Otp resend successfully')
    }
  }, [resendOtpSuccess])

  //table columns
  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Customer Id',
      dataKey: 'requestCode',
      cell: <Cell dataKey='requestCode' />,
      sortable: false,
      width: 230,
    },

    {
      label: 'Name',
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Email',
      dataKey: 'email',
      cell: <Cell dataKey='email' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      width: 230,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },

    {
      label: 'Action',
      width: 100,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleRefresh = () => {
    dispatch(fdRequestUnverifiedRedux.actions.getAllFDRequestUnverified(params))
  }

  const handleExport = () => {
    dispatch(fdRequestUnverifiedRedux.actions.exportFile('FD Request Unverified List Data', params))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='FD Request Unverified'
          params={params}
          setParams={setParams}
          addShow={false}
          deleteShow={false}
          filterData={true}
          handleRefresh={handleRefresh}
          importShow={false}
          exportShow={true}
          toggleMultipleShow={false}
          data={data}
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
          handleExport={handleExport}
        />
        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={preliminaryVerifiedData?.loading}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={preliminaryVerifiedData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

export default FdRequestUnverified
