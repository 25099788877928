import moment from 'moment'
import {useEffect, useState} from 'react'
import AddressDetails from './addressDetails'
import BasicDetails from './basicDetails'
import FamilyDetails from './familyDetails'
import GuardianDetail from './guardianDetails'

type Props = {
  data?: any
  documents?: any
}

const PersonalInformation = ({data, documents}: Props) => {
  const [isMinor, setIsMinor] = useState(false)
  const [isMarried, setIsMarried] = useState(false)
  const [isApplyingFromInsideCountry, setApplyingFromInsideCountry] = useState(true)

  useEffect(() => {
    if (data?.accountType?.cbs_code === 'MINOR') {
      setIsMinor(true)
    }
    if (
      data?.personalInformation?.maritalStatus?.title === 'M Married' ||
      data?.personalInformation?.maritalStatus?.title === 'Married' ||
      data?.personalInformation?.maritalStatus?.cbs_code === 'M'
    ) {
      setIsMarried(true)
    }
    if (data?.applyFrom === '0') {
      setApplyingFromInsideCountry(false)
    }
  }, [data])

  return (
    <div>
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Personal Information</h3>
      </div>
      <div className='mb-1 text-primary fs-5 fw-bold'>
        Applied Date: {moment(data?.appliedDate).format('YYYY-MM-DD')}
      </div>
      <BasicDetails
        data={data?.personalInformation}
        salutation={data?.salutation}
        isMinor={isMinor}
        documents={documents}
        isNrn={data?.isNrn}
        dobAD={data?.dobAd}
        dobBS={data?.dobBs}
      />
      <AddressDetails
        addressDetails={
          data?.personalInformationAddressCollectionData?.personalInformationAddressData
        }
        applyingFromInside={isApplyingFromInsideCountry}
      />
      <FamilyDetails
        familyDetails={data?.personalInformationRelationCollection?.personalInformationRelationData}
        isMarried={isMarried}
        spouseName={data?.personalInformation?.spouseName}
      />
      {isMinor && (
        <GuardianDetail
          guardianDetail={data?.guardianInformationCollection?.guardianInformationData?.[0]}
          documents={documents}
          maturityDate={data?.personalInformation?.majorityDate}
        />
      )}
    </div>
  )
}

export default PersonalInformation
