import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// includes
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import DynamicSettings from './../../../../common/components/settings'

const Settings = () => {
  const dispatch = useDispatch()
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  
  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  const settingType: any = settingTypeData?.data?.settingType
    ? settingTypeData?.data?.settingType?.find((item: any) => item.name === 'Backend')
    : {}


    /* const [logo, setLogo] = useState('')
    const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  
    useEffect(() => {
      if (!isEmpty(settingTypeData?.backendData)) {
        settingTypeData?.backendData &&
          settingTypeData?.backendData['Theme settings']?.map((item: any) => {
            if (item?.name === 'mainLogo') {
              setLogo(item?.value)
            }
          })
      }
    }, [settingTypeData]) */
    

  return (
    <>
      <DynamicSettings
        settingTypeId={settingType?.id}
        settingName='Backend Settings'
        settingTypeName={settingType?.name}
      />
    </>
  )
}

export default Settings
