import {useEffect, useState} from 'react'
import ImagePreview from '../ImagePreview'
import SingleColumn from '../SingleColumn'

type Props = {
  employeeNumber?: string
  documents?: Array<any>
}

const EmployeeInformation = ({employeeNumber, documents}: Props) => {
  const [employeeCardURL, setEmployeeCardURL] = useState({
    front: '',
    back: '',
    file: '',
  })

  useEffect(() => {
    const file = documents?.find(
      (element: any) =>
        element?.fileType?.code === 'professional' &&
        element?.fileType?.document_type === 'Employee' &&
        element?.fileType?.title === 'Employee ID Card'
    )
    setEmployeeCardURL({
      file: file?.file,
      front: file?.fileFront,
      back: file?.fileBack,
    })
  }, [])

  return (
    <>
      <div className='mb-1 text-primary fs-5 fw-bold'>Employee Information</div>
      <div className='row'>
        {/* <div className='mb-3 col-12 col-md-6 col-lg-4'> */}
        <SingleColumn label='Employee Number' value={employeeNumber ?? ''} />

        <div className='mb-3 col-12 col-md-6 col-lg-4'>
          <ImagePreview imageSrc={employeeCardURL.file} label='Employee Card' />
        </div>

        {/* </div> */}
      </div>
    </>
  )
}

export default EmployeeInformation
