import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_PENDING_ACCOUNT_LIST = `${API_URL}/customer-data`
export const GET_PENDING_LIST_EXPORT = `${API_URL}/approve-list/export`

export const service = {
  getPendingAccountList: (params: ParamsModel) => {
    return axios.get(GET_PENDING_ACCOUNT_LIST, {params})
  },

  exportPENDINGList: (fileName: string, params: ParamsModel) => {
    return axios.get(`${GET_PENDING_LIST_EXPORT}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
