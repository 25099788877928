import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getCheckerRepairListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getCheckerRepairList, params)
    const data: any = response?.data?.data

    yield put(actions.getAllCheckerRepairListSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllCheckerRepairListError(error))
  }
}

function* exportMakerRepairListFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(service.exportMakerRepairListFile, fileName, params)
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CHECKER_REPAIR_LIST_START, getCheckerRepairListSaga)
  yield takeLatest(actionTypes.EXPORT_REPAIR_FILE_START, exportMakerRepairListFileSaga)
}
