import FDAccountDetails from 'src/app/modules/common/components/FDInformation'

const FDRequestDetails = () => {
  const statusDropdown = [
    {value: 'Accept', label: 'Accept'},
    {value: 'Reject', label: 'Reject'},
  ]
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <FDAccountDetails
        displayChangeStatus={true}
        options={statusDropdown}
        displayAccountNumberField={true}
      />
    </div>
  )
}

export default FDRequestDetails
