import { ParamsModel } from 'src/app/modules/common/Model'
import { ProductManagerModel, SortProductManagerModel } from '../Model'
import { actionTypes } from './constants'

export const actions = {
    getAllProductManager: (params: ParamsModel = { page: 1, limit: 10 }) => ({
        type: actionTypes.GET_ALL_PRODUCT_MANAGER_START,
        payload: { params }
    }),

    getAllProductManagerSuccess: (data: ProductManagerModel | any) => ({
        type: actionTypes.GET_ALL_PRODUCT_MANAGER_SUCCESS,
        payload: data
    }),

    getAllProductManagerError: (data: any) => ({
        type: actionTypes.GET_ALL_PRODUCT_MANAGER_FINISH,
        payload: data
    }),

    //Enable ProductManager
    enableProductManager: (data: { id: string }[]) => ({
        type: actionTypes.ENABLE_PRODUCT_MANAGER_REQUEST,
        payload: { data },
    }),

    enableProductManagerSuccess: (task: any) => ({
        type: actionTypes.ENABLE_PRODUCT_MANAGER_SUCCESS,
        payload: task,
    }),
    enableProductManagerFinish: () => ({
        type: actionTypes.ENABLE_PRODUCT_MANAGER_FINISH,
    }),

    //Disable ProductManager
    disableProductManager: (data: { id: string }[]) => ({
        type: actionTypes.DISABLE_PRODUCT_MANAGER_REQUEST,
        payload: { data },
    }),

    disableProductManagerSuccess: (task: any) => ({
        type: actionTypes.DISABLE_PRODUCT_MANAGER_SUCCESS,
        payload: task,
    }),
    disableProductManagerFinish: () => ({
        type: actionTypes.DISABLE_PRODUCT_MANAGER_FINISH,
    }),

    singleDisableProductManager: (data: { id: string }) => ({
        type: actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST,
        payload: { data }
    }),

    singleDisableProductManagerSuccess: (task: any) => ({
        type: actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS,
        payload: task,
    }),

    singleDisableProductManagerFinish: () => ({
        type: actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_FINISH,
    }),

    singleEnableProductManager: (data: { id: string }) => ({
        type: actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST,
        payload: { data },
    }),

    singleEnableProductManagerSuccess: (task: any) => ({
        type: actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS,
        payload: task,
    }),

    singleEnableProductManagerFinish: () => ({
        type: actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_FINISH,
    }),

    deleteProductManager: (data: { id: string }[]) => ({
        type: actionTypes.DELETE_PRODUCT_MANAGER_START,
        payload: { productManagerId: data },
    }),

    deleteProductManagerSuccess: (data: any) => ({
        type: actionTypes.DELETE_PRODUCT_MANAGER_SUCCESS,
        payload: data,
    }),

    deleteProductManagerFinish: () => ({
        type: actionTypes.DELETE_PRODUCT_MANAGER_FINISH,
    }),

    addProductManager: (data: ProductManagerModel | any) => ({
        type: actionTypes.ADD_PRODUCT_MANAGER_START,
        payload: data
    }),

    addProductManagerSuccess: (data: ProductManagerModel | any) => ({
        type: actionTypes.ADD_PRODUCT_MANAGER_SUCCESS,
        payload: data
    }),

    addProductManagerFinish: () => ({
        type: actionTypes.ADD_PRODUCT_MANAGER_FINISH
    }),

    resetProductManager: () => ({
        type: actionTypes.RESET_PRODUCT_MANAGER
    }),

    updateProductManager: (data: ProductManagerModel | any, id: string) => ({
        type: actionTypes.UPDATE_PRODUCT_MANAGER_START,
        payload: data,
        id,
    }),

    updateProductManagerSuccess: (data: any) => ({
        type: actionTypes.UPDATE_PRODUCT_MANAGER_SUCCESS,
        payload: data,
    }),

    updateProductManagerFinish: () => ({
        type: actionTypes.UPDATE_PRODUCT_MANAGER_FINISH,
    }),

    sortProductManager: (data: SortProductManagerModel) => ({
        type: actionTypes.SORT_PRODUCT_MANAGER_START,
        payload: data,
    }),

    sortProductManagerSuccess: (data: Array<ProductManagerModel>) => ({
        type: actionTypes.SORT_PRODUCT_MANAGER_SUCCESS,
        payload: data,
    }),

    sortProductManagerFinish: () => ({
        type: actionTypes.SORT_PRODUCT_MANAGER_FINISH,
    }),

    sortProductManagerReset: () => ({
        type: actionTypes.SORT_PRODUCT_MANAGER_RESET,
    }),
}


