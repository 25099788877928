import SingleColumn from "../SingleColumn"

type Props = {
    anticipatedVolume?: any
}

const AnticipatedVolume = ({ anticipatedVolume }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Transaction Volume</div>
            <div className="row">
                <SingleColumn label="Approximate annual income/salary" value={anticipatedVolume?.annualSalaryIncome} />
                <SingleColumn label="Expected annual volume of transaction" value={anticipatedVolume?.annualTurnOver} />
                <SingleColumn label="Expected monthly volume of transaction" value={anticipatedVolume?.monthlyTurnOver} />
                <SingleColumn label="Expected number of annual transaction" value={anticipatedVolume?.noOfAnnualTransaction} />
                <SingleColumn label="Expected number of monthly transaction" value={anticipatedVolume?.noOfMonthlyTransaction} />
            </div>

        </>
    )
}

export default AnticipatedVolume