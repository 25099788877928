import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './actions'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {SortFDProductManagerActionModel, SortFDProductManagerModel} from '../Model'

function* getAllFDProductManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllFDProductManager, params)
    const data: any = response?.data
    yield put(actions.getAllFDProductManagerSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllFDProductManagerError(error))
  }
}

function* enableFDProductManagerSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableFDProductManager, selectedUsers)
    yield put(actions.enableFDProductManagerSuccess(response?.data))
    yield put(actions.enableFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableFDProductManagerFinish())
  }
}

function* disableFDProductManagerSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableFDProductManager, selectedUsers)
    yield put(actions.disableFDProductManagerSuccess(response?.data))
    yield put(actions.disableFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableFDProductManagerFinish())
  }
}

function* singleEnableFDProductManagerSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableFDProductManager, selectedUsers)
    yield put(actions.singleEnableFDProductManagerSuccess(response?.data))
    yield put(actions.singleEnableFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableFDProductManagerFinish())
  }
}

function* singleDisableFDProductManagerSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableFDProductManager, selectedUsers)
    yield put(actions.singleDisableFDProductManagerSuccess(response?.data))
    yield put(actions.singleDisableFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableFDProductManagerFinish())
  }
}

function* deleteFDProductManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteFDProductManager, body)
    yield put(actions.deleteFDProductManagerSuccess(response.data?.data))
    yield put(actions.deleteFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteFDProductManagerFinish())
  }
}

function* addFDProductManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addFDProductManager, body)
    yield put(actions.addFDProductManagerSuccess(response.data?.data))
    yield put(actions.addFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addFDProductManagerFinish())
  }
}

function* updateFDProductManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateFDProductManager,
      body,
      action.payload?.id
    )
    yield put(actions.updateFDProductManagerSuccess(response.data?.data))
    yield put(actions.updateFDProductManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateFDProductManagerFinish())
  }
}

function* sortFDProductManager(action: SortFDProductManagerActionModel) {
  try {
    const body: SortFDProductManagerModel = action.payload
    const response: ResponseModel = yield call(service.sortFDProductManager, body)

    yield put({
      type: actionTypes.SORT_FD_PRODUCT_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_FD_PRODUCT_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_FD_PRODUCT_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_FD_PRODUCT_MANAGER_START, getAllFDProductManagerSaga)
  yield takeLatest(actionTypes.ENABLE_FD_PRODUCT_MANAGER_REQUEST, enableFDProductManagerSaga)
  yield takeLatest(actionTypes.DISABLE_FD_PRODUCT_MANAGER_REQUEST, disableFDProductManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_FD_PRODUCT_MANAGER_REQUEST,
    singleEnableFDProductManagerSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_FD_PRODUCT_MANAGER_REQUEST,
    singleDisableFDProductManagerSaga
  )
  yield takeLatest(actionTypes.DELETE_FD_PRODUCT_MANAGER_START, deleteFDProductManagerSaga)
  yield takeLatest(actionTypes.ADD_FD_PRODUCT_MANAGER_START, addFDProductManagerSaga)
  yield takeLatest(actionTypes.UPDATE_FD_PRODUCT_MANAGER_START, updateFDProductManagerSaga)
  yield takeLatest(actionTypes.SORT_FD_PRODUCT_MANAGER_START, sortFDProductManager)
}
