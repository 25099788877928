import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {ErrorMessage, Form, Formik} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import {useEffect, useState} from 'react'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {isEmpty} from 'lodash'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import * as yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import * as productManagerRedux from 'src/app/modules/products/productManager/redux'
import {toast} from 'react-toastify'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType?: string
  editSelectedData?: any
}

function convertToSlug(text: any) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const AddProductManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [attachedFile, setAttachedFile] = useState('')
  const [attachfile, setAttachfile] = useState('')

  const {loading, success} = useSelector((state: any) => state.productManager)

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Product manager added successfully')
        : toast.success('Product manager edited successfully')
      dispatch(productManagerRedux.actions.getAllProductManager(params))
      dispatch(productManagerRedux.actions.resetProductManager())
      handleClose()
    }
  }, [success])

  const validationSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    // slug: yup.string().required("Slug is required"),
    // interestRate: yup.string().required("Interest rate is required"),
    // minimumBalance: yup.string().required("Minimum balance is required"),
    // paymentFrequency: yup.string().required("Payment frequency is required"),
    // description: yup.string().required("Description is required"),
    // first_button: yup.string().required("First button name is required"),
    // first_button_link: yup.string().required("First button link is required")
  })

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>{actionType} Product Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                // slug: "",
                interestRate: '',
                minimumBalance: '',
                paymentFrequency: '',
                description: '',
                first_button: '',
                first_button_link: '',
                // second_button: "",
                // second_button_link: "",
                isEmployee: false,
                image: '',
              }}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    productManagerRedux.actions.updateProductManager(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(productManagerRedux?.actions?.addProductManager(formData))
                }
              }}
              validationSchema={validationSchema}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  let key: any
                  for (key in editSelectedData) {
                    setFieldValue(key, editSelectedData[key], false)
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            label='Title'
                            onKeyUp={(event: any) => {
                              if (isEmpty(editSelectedData)) {
                                const val = convertToSlug(event.target.value)
                                setFieldValue('slug', val, false)
                              }
                            }}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Slug'
                            disabled
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Interest Rate'
                            name='interestRate'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Interest Rate'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Minimum Balance'
                            name='minimumBalance'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Minimum Balance'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Name'
                            name='first_button'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Name'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Link'
                            name='first_button_link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Link'
                          />
                        </div>
                      </div>
                      {/* <div className='row'>
                                                <div className='col-md-6 col-xs-12'>
                                                    <FormTextBox
                                                        type="text"
                                                        labelClassName='col-md-12'
                                                        containerClassName='col-md-12'
                                                        placeholder="Second Button Name"
                                                        name="second_button"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        touched={touched}
                                                        label='Second Button Name'
                                                    />
                                                </div>

                                                <div className='col-md-6 col-xs-12'>
                                                    <FormTextBox
                                                        type="text"
                                                        labelClassName='col-md-12'
                                                        containerClassName='col-md-12'
                                                        placeholder="Second Button Link"
                                                        name="second_button_link"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        touched={touched}
                                                        label='Second Button Link'
                                                    />
                                                </div>
                                            </div> */}
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Payment Frequency'
                            name='paymentFrequency'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Payment Frequency'
                          />
                          {/* <FormSelect
                                                        labelClassName='col-md-12'
                                                        containerClassName='col-md-12'
                                                        placeholder='Please select payment frequency'
                                                        label='Payment Frequency'
                                                        name='paymentFrequency'
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        touched={touched}
                                                        options={[]}
                                                        required={true}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                    /> */}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Attached File'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.image}
                          />
                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('image', '')
                                    }}
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.image.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.image}`
                                    }
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-12 col-xs-12'>
                          <FormCheckbox
                            name='isEmployee'
                            checkBoxText='Employee Account'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checked={
                              !isEmpty(editSelectedData) ? editSelectedData?.isEmployee : false
                            }
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <FormTinyMce
                          containerClassName='col-md-12'
                          label='Description'
                          name='description'
                          initialValue={
                            !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                          }
                          handleChange={handleChange}
                        />
                      </div>

                      <div className='d-flex justify-content-end px-9'>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn-sm ms-3'
                        >
                          Save
                        </button>
                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddProductManager
