// import { ParamsModel } from "../Model/index";
import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {AccountFdApprovedState} from './reducer'

export const actions = {
  getAllFdApprovedList: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_ACCOUNT_FD_APPROVED_LIST_START,
      payload: {params},
    }
  },

  getAllFdApprovedListSuccess: (data: AccountFdApprovedState) => ({
    type: actionTypes.GET_ACCOUNT_FD_APPROVED_LIST_SUCCESS,
    payload: data,
  }),

  getAllFdApprovedListError: (data: AccountFdApprovedState) => ({
    type: actionTypes.GET_ACCOUNT_FD_APPROVED_LIST_FINISH,
    payload: data,
  }),

  //export file
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_FD_APPROVED_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_FD_APPROVED_FILE_SUCCESS,
    payload: data,
  }),

  exportFileError: () => ({
    type: actionTypes.EXPORT_FD_APPROVED_FILE_FINISH,
  }),
}
