export const actionTypes = {
  //get preliminary verified
  GET_ACCOUNT_FD_REJECTED_LIST_START: 'GET_ACCOUNT_FD_REJECTED_LIST_START',
  GET_ACCOUNT_FD_REJECTED_LIST_SUCCESS: 'GET_ACCOUNT_FD_REJECTED_LIST_SUCCESS',
  GET_ACCOUNT_FD_REJECTED_LIST_FINISH: 'GET_ACCOUNT_FD_REJECTED_LIST_FINISH',

  //get Export file
  EXPORT_FD_REJECTED_FILE_START: 'EXPORT_FD_REJECTED_FILE_START',
  EXPORT_FD_REJECTED_FILE_SUCCESS: 'EXPORT_FD_REJECTED_FILE_SUCCESS',
  EXPORT_FD_REJECTED_FILE_FINISH: 'EXPORT_FD_REJECTED_FILE_FINISH',
}
