import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface MakerListState {
  data: {
    accountOpeningDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortVerifiedData?: MakerListState[]
  customerDetailList?: {accountOpeningDetail: any[] | any}
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
  syncSuccess: false
  customerId: ''
  // isFetchingPdf: false,
}

const InitialMakerListState: MakerListState = {
  data: {
    accountOpeningDetail: [],
    meta: {},
  },
  sortVerifiedData: [],
  customerDetailList: {accountOpeningDetail: []},
  loading: false,
  success: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
  syncSuccess: false,
  customerId: '',
}

export const reducer = (
  state: MakerListState = InitialMakerListState,
  action: ActionWithPayload<MakerListState>
) => {
  switch (action.type) {
    case actionTypes.GET_MAKER_LIST_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_MAKER_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_MAKER_LIST_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }

    case actionTypes.EXPORT_MAKER_FILE_START: {
      return {...state}
    }

    case actionTypes.EXPORT_MAKER_FILE_SUCCESS: {
      return {...state, success: true}
    }

    case actionTypes.EXPORT_MAKER_FILE_FINISH: {
      const error = action.payload
      return {...state, error, success: false}
    }

    case actionTypes.ADD_STATUS_REMARKS_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_STATUS_REMARKS_SUCCESS: {
      // const remarksData = action.payload
      return {...state, formSuccess: true, loading: false}
    }

    case actionTypes.ADD_STATUS_REMARKS_FINISH: {
      const error = action.payload
      return {...state, error, formSuccess: false, loading: false}
    }

    case actionTypes.ADD_STATUS_REMARKS_RESET: {
      return {...state, formSuccess: false, data: [], loading: false}
    }

    case actionTypes.SYNC_ACCOUNT_DATA_START: {
      return {
        ...state,
        customerId: action?.payload?.customerId,
        loading: true,
      }
    }

    case actionTypes.SYNC_ACCOUNT_DATA_SUCCESS: {
      const selectedCustomerId = action?.payload?.customerId
      let newData = state?.data?.accountOpeningDetail?.map((data) => {
        if (data?.customerId === selectedCustomerId) {
          const x: any = {...data}
          return {
            ...x,
            status: {
              ...x.status,
              kyc_status: 'Submitted',
            },
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {
          ...state,
          accountOpeningDetail: newData,
          meta: state?.data?.meta,
        },
        loading: false,
        syncSuccess: true,
      }
    }

    case actionTypes.SYNC_ACCOUNT_DATA_FINISH: {
      return {
        ...state,
        loading: false,
        syncSuccess: false,
        customerId: '',
      }
    }

    default:
      return state
  }
}
