import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getMakerListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getNewMakerList, params)
    const data: any = response?.data?.data

    yield put(actions.getAllNewMakerListSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllNewMakerListError(error))
  }
}

function* getLogSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getLogList, params)
    const data: any = response?.data?.data

    yield put(actions.getAllLogSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllLogError(error))
  }
}

function* exportMakerListFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(
      service.exportPreliminaryVerifiedFile,
      fileName,
      params
    )
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

function* createRemarksSaga(action: ActionModel) {
  try {
    const body: any = action?.payload

    const response: ResponseModel = yield call(service.updateStatus, body)
    yield put({type: actionTypes.ADD_REMARKS_SUCCESS, payload: response.data})
    yield put({type: actionTypes.ADD_REMARKS_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_REMARKS_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_NEW_MAKER_LIST_START, getMakerListSaga)
  yield takeLatest(actionTypes.GET_LOG_START, getLogSaga)
  yield takeLatest(actionTypes.EXPORT_MAKER_FILE_START, exportMakerListFileSaga)
  yield takeLatest(actionTypes.ADD_REMARKS_REQUEST, createRemarksSaga)
}
