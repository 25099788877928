export const actionTypes = {
  //get preliminary verified
  GET_MAKER_LIST_START: 'GET_MAKER_LIST_START',
  GET_MAKER_LIST_SUCCESS: 'GET_MAKER_LIST_SUCCESS',
  GET_MAKER_LIST_FINISH: 'GET_MAKER_LIST_FINISH',

  //get Export file
  EXPORT_MAKER_FILE_START: 'EXPORT_MAKER_FILE_START',
  EXPORT_MAKER_FILE_SUCCESS: 'EXPORT_MAKER_FILE_SUCCESS',
  EXPORT_MAKER_FILE_FINISH: 'EXPORT_MAKER_FILE_FINISH',

  //ADD_STATUS Remarks
  ADD_STATUS_REMARKS_REQUEST: 'ADD_STATUS_REMARKS_REQUEST',
  ADD_STATUS_REMARKS_SUCCESS: 'ADD_STATUS_REMARKS_SUCCESS',
  ADD_STATUS_REMARKS_FINISH: 'ADD_STATUS_REMARKS_FINISH',
  ADD_STATUS_REMARKS_RESET: 'ADD_STATUS_REMARKS_RESET',

  //sync
  SYNC_ACCOUNT_DATA_START: 'SYNC_ACCOUNT_DATA_START',
  SYNC_ACCOUNT_DATA_SUCCESS: 'SYNC_ACCOUNT_DATA_SUCCESS',
  SYNC_ACCOUNT_DATA_FINISH: 'SYNC_ACCOUNT_DATA_FINISH',
}
