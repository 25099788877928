import { useEffect, useState } from "react"
import Citizenship from "../Citizenship"
import Other from "../Other"
import Passport from "../Passport"
import SingleColumn from "../SingleColumn"

type Props = {
    identificationInformation?: any,
    documents?: any
}

const IdentificationData = ({ identificationInformation, documents }: Props) => {
    const [identificationType, setIdentificationType] = useState("");
    const [files, setFiles] = useState({
        file: "",
        front: "",
        back: ""
    });

    useEffect(() => {
        setIdentificationType(identificationInformation?.idType?.title);
    }, [identificationInformation])

    useEffect(() => {
        if (identificationType === "Citizenship") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Citizenship")
            setFiles({
                file: "",
                front: file?.fileFront,
                back: file?.fileBack
            });
        }
        if (identificationType === "Passport") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Passport")
            setFiles({
                file: file?.file,
                front: file?.fileFront,
                back: file?.fileBack
            });
        }
        if (identificationType === "Others") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Other")
            setFiles({
                file: file?.file,
                front: file?.fileFront,
                back: file?.fileBack
            });
        }
    }, [documents, identificationType])
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Identification Details</div>
            <div className="row">
                <SingleColumn label="Identification Type" value={identificationInformation?.idType?.title} />
            </div>
            <div className="row">
                {
                    {
                        Citizenship: <Citizenship data={identificationInformation} frontURL={files.front} backURL={files.back} />,
                        Passport: <Passport data={identificationInformation} fileURL={files.file} />,
                        Others: <Other data={identificationInformation} fileURL={files.file} />,
                    }[identificationType]
                }
            </div>
        </>
    )
}

export default IdentificationData