export const actionTypes = {
  //get preliminary verified
  GET_NEW_MAKER_LIST_START: 'GET_NEW_MAKER_LIST_START',
  GET_NEW_MAKER_LIST_SUCCESS: 'GET_NEW_MAKER_LIST_SUCCESS',
  GET_NEW_MAKER_LIST_FINISH: 'GET_NEW_MAKER_LIST_FINISH',

  //get Export file
  EXPORT_MAKER_FILE_START: 'EXPORT_MAKER_FILE_START',
  EXPORT_MAKER_FILE_SUCCESS: 'EXPORT_MAKER_FILE_SUCCESS',
  EXPORT_MAKER_FILE_FINISH: 'EXPORT_MAKER_FILE_FINISH',

  // get get Log
  GET_LOG_START: 'GET_LOG_START',
  GET_LOG_SUCCESS: 'GET_LOG_SUCCESS',
  GET_LOG_FINISH: 'GET_LOG_FINISH',

  //Add Remarks
  ADD_REMARKS_REQUEST: 'ADD_REMARKS_REQUEST',
  ADD_REMARKS_SUCCESS: 'ADD_REMARKS_SUCCESS',
  ADD_REMARKS_FINISH: 'ADD_REMARKS_FINISH',
  ADD_REMARKS_RESET: 'ADD_REMARKS_RESET',
}
