export const actionTypes = {
  GET_ALL_FD_PRODUCT_MANAGER_START: 'GET_ALL_FD_PRODUCT_MANAGER_START',
  GET_ALL_FD_PRODUCT_MANAGER_SUCCESS: 'GET_ALL_FD_PRODUCT_MANAGER_SUCCESS',
  GET_ALL_FD_PRODUCT_MANAGER_FINISH: 'GET_ALL_FD_PRODUCT_MANAGER_FINISH',

  ADD_FD_PRODUCT_MANAGER_START: 'ADD_FD_PRODUCT_MANAGER_START',
  ADD_FD_PRODUCT_MANAGER_SUCCESS: 'ADD_FD_PRODUCT_MANAGER_SUCCESS',
  ADD_FD_PRODUCT_MANAGER_FINISH: 'ADD_FD_PRODUCT_MANAGER_FINISH',
  RESET_FD_PRODUCT_MANAGER: 'RESET_FD_PRODUCT_MANAGER',

  UPDATE_FD_PRODUCT_MANAGER_START: 'UPDATE_FD_PRODUCT_MANAGER_START',
  UPDATE_FD_PRODUCT_MANAGER_SUCCESS: 'UPDATE_FD_PRODUCT_MANAGER_SUCCESS',
  UPDATE_FD_PRODUCT_MANAGER_FINISH: 'UPDATE_FD_PRODUCT_MANAGER_FINISH',

  DELETE_FD_PRODUCT_MANAGER_START: 'DELETE_FD_PRODUCT_MANAGER_START',
  DELETE_FD_PRODUCT_MANAGER_SUCCESS: 'DELETE_FD_PRODUCT_MANAGER_SUCCESS',
  DELETE_FD_PRODUCT_MANAGER_FINISH: 'DELETE_FD_PRODUCT_MANAGER_FINISH',

  SINGLE_DISABLE_FD_PRODUCT_MANAGER_REQUEST: 'SINGLE_DISABLE_FD_PRODUCT_MANAGER_REQUEST',
  SINGLE_DISABLE_FD_PRODUCT_MANAGER_SUCCESS: 'SINGLE_DISABLE_FD_PRODUCT_MANAGER_SUCCESS',
  SINGLE_DISABLE_FD_PRODUCT_MANAGER_FINISH: 'SINGLE_DISABLE_FD_PRODUCT_MANAGER_FINISH',

  SINGLE_ENABLE_FD_PRODUCT_MANAGER_REQUEST: 'SINGLE_ENABLE_FD_PRODUCT_MANAGER_REQUEST',
  SINGLE_ENABLE_FD_PRODUCT_MANAGER_SUCCESS: 'SINGLE_ENABLE_FD_PRODUCT_MANAGER_SUCCESS',
  SINGLE_ENABLE_FD_PRODUCT_MANAGER_FINISH: 'SINGLE_ENABLE_FD_PRODUCT_MANAGER_FINISH',

  ENABLE_FD_PRODUCT_MANAGER_REQUEST: 'ENABLE_FD_PRODUCT_MANAGER_REQUEST',
  ENABLE_FD_PRODUCT_MANAGER_SUCCESS: 'ENABLE_FD_PRODUCT_MANAGER_SUCCESS',
  ENABLE_FD_PRODUCT_MANAGER_FINISH: 'ENABLE_FD_PRODUCT_MANAGER_FINISH',

  // Disable PRODUCT_TAG
  DISABLE_FD_PRODUCT_MANAGER_REQUEST: 'DISABLE_FD_PRODUCT_MANAGER_REQUEST',
  DISABLE_FD_PRODUCT_MANAGER_SUCCESS: 'DISABLE_FD_PRODUCT_MANAGER_SUCCESS',
  DISABLE_FD_PRODUCT_MANAGER_FINISH: 'DISABLE_FD_PRODUCT_MANAGER_FINISH',

  // sort
  SORT_FD_PRODUCT_MANAGER_START: 'SORT_FD_PRODUCT_MANAGER_START',
  SORT_FD_PRODUCT_MANAGER_SUCCESS: 'SORT_FD_PRODUCT_MANAGER_SUCCESS',
  SORT_FD_PRODUCT_MANAGER_FINISH: 'SORT_FD_PRODUCT_MANAGER_FINISH',
  SORT_FD_PRODUCT_MANAGER_RESET: 'SORT_FD_PRODUCT_MANAGER_RESET',
}
