// import { ParamsModel } from "../Model/index";
import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {MakerListState} from './reducer'

export const actions = {
  getAllMakerList: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_MAKER_LIST_START,
      payload: {params},
    }
  },

  getAllMakerListSuccess: (data: MakerListState) => ({
    type: actionTypes.GET_MAKER_LIST_SUCCESS,
    payload: data,
  }),

  getAllMakerListError: (data: MakerListState) => ({
    type: actionTypes.GET_MAKER_LIST_FINISH,
    payload: data,
  }),

  //export file
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_MAKER_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_MAKER_FILE_SUCCESS,
    payload: data,
  }),

  exportFileError: () => ({
    type: actionTypes.EXPORT_MAKER_FILE_FINISH,
  }),

  //add Remarks
  addRemarks: (data: any) => ({
    type: actionTypes.ADD_STATUS_REMARKS_REQUEST,
    payload: data,
  }),

  addRemarksSuccess: (task: any) => ({
    type: actionTypes.ADD_STATUS_REMARKS_SUCCESS,
    payload: task,
  }),

  addRemarksFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_STATUS_REMARKS_FINISH,
    payload: errorMsg,
  }),

  addRemarksReset: () => ({
    type: actionTypes.ADD_STATUS_REMARKS_RESET,
  }),

  //sync
  syncAccountData: (customerId: string) => {
    return {
      type: actionTypes.SYNC_ACCOUNT_DATA_START,
      payload: {customerId},
    }
  },

  syncAccountDataSuccess: (task: any) => {
    return {
      type: actionTypes.SYNC_ACCOUNT_DATA_SUCCESS,
      payload: task,
    }
  },

  syncAccountDataFinish: () => {
    return {
      type: actionTypes.SYNC_ACCOUNT_DATA_FINISH,
    }
  },
}
