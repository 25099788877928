import Modal from 'rsuite/Modal'

interface Props {
  isOpen?: boolean
  handleClick: () => void
  handleClose: () => void
  remarks: string
  setRemarks: Function
  department: string
  statusList: Array<any>
  disableSubmitButton: boolean
  status: string
  setStatus: Function
}
const GrievanceActionsModal = ({
  isOpen,
  disableSubmitButton,
  handleClick,
  handleClose,
  remarks,
  setRemarks,
  status,
  setStatus,
  statusList,
}: Props) => {
  return (
    <>
      <Modal backdrop='static' role='alertdialog' open={isOpen} size='xs' enforceFocus={false}>
        <Modal.Body>
          <div className='d-flex'>
            <h4>
              <strong className='ms-3'>Edit Query</strong>
            </h4>
          </div>
          <div className='mx-3 mt-2'>
            <label>Select Status</label>
            <select
              placeholder='Select Status'
              style={{
                display: 'block',
                border: 'solid 1px rgba(0,0,0,0.2)',
                padding: '5px 10px',
                background: 'inherit',
                outline: 'none',
              }}
              onChange={(e) => {
                setStatus(e.target.value)
                setRemarks('')
              }}
            >
              <option value='Select Status' selected={status === 'Select Status'}>
                Select Status
              </option>
              {statusList?.map((dta) => {
                return (
                  <option
                    key={dta.id}
                    value={dta.display_name}
                    selected={status === dta.display_name}
                  >
                    {dta.display_name}
                  </option>
                )
              })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClick}
            disabled={disableSubmitButton}
            className='btn btn-primary btn-sm ms-3'
          >
            Submit
          </button>

          <button onClick={handleClose} className='btn btn-secondary btn-sm ms-3'>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GrievanceActionsModal
