import SingleColumn from "../SingleColumn"

type Props = {
    studentDetails?: any
}

const StudentDetails = ({ studentDetails }: Props) => {
    return (

        <>
            {studentDetails?.length > 0 && <div className="mb-1 text-primary fs-5 fw-bold">Student Details</div>}
            {studentDetails?.map((element: any, index: number) => (
                <div className="row" key={index}>
                    <SingleColumn label="Name of college / institution" value={element?.institutionName} />
                    <SingleColumn label="Address" value={element?.address} />
                    <SingleColumn label="Phone Number" value={element?.phoneNumber} />
                </div>
            ))}
        </>
    )
}

export default StudentDetails