import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './action'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

function* getFDRequestUnverifiedSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getFDRequestUnverified, params)
    const data: any = response?.data
    yield put(actions.getAllPreliminaryUnverifiedSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllPreliminaryUnverifiedError(error))
  }
}

function* exportFDRequestUnverifiedFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(
      service.exportFDRequestUnverifiedFile,
      fileName,
      params
    )
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

function* resendOTPSaga(action: ActionModel) {
  try {
    yield call(service.resendOTP, action.payload.customerId)
    yield put(actions.resendOtpSuccess())
    yield put(actions.resendOtpFinish())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.resendOtpFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_FD_REQUEST_UNVERIFIED_START, getFDRequestUnverifiedSaga)
  yield takeLatest(actionTypes.EXPORT_FD_UNVERIFIED_FILE_START, exportFDRequestUnverifiedFileSaga)
  yield takeLatest(actionTypes.RESEND_FD_REQUEST_OTP_CODE_START, resendOTPSaga)
}
