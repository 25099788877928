import { useEffect, useState } from "react";
import ImagePreview from "../ImagePreview";
import SingleColumn from "../SingleColumn";

type Props = {
    guardianDetail?: any,
    documents?: any,
    maturityDate?: any
}

const GuardianDetail = ({ guardianDetail, documents, maturityDate }: Props) => {
    const [guardianPhoto, setGuardianPhoto] = useState("");

    useEffect(() => {
        const guardian = documents?.find((element: any) => element?.fileType?.code === "personal" && element?.fileType?.title === "Guardian Passport Photo")
        setGuardianPhoto(guardian?.file);
    }, [documents])
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Guardian Details</div>
            <div className="row">
                <SingleColumn label="Guardian Name" value={guardianDetail?.firstName} />
                <SingleColumn label="Guardian Address" value={guardianDetail?.address} />
                <SingleColumn label="Guardian Account No." value={guardianDetail?.accountNumber} />
                <SingleColumn label="Relationship with minor" value={guardianDetail?.relation?.title} />
                <SingleColumn label="Date of Attaining maturity" value={maturityDate} />
                <div className="col-12">
                    <div className="row">
                        <div className='mb-3 col-12 col-md-6 col-lg-4'>
                            <ImagePreview imageSrc={guardianPhoto} label="Guardian PP Photo:" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuardianDetail