import {useLocation} from 'react-router-dom'
import FDAccountDetails from 'src/app/modules/common/components/FDInformation'

const FDPreliminaryUnverifiedDetail = () => {
  const location = useLocation()
  // const history = useHistory();
  // const dispatch = useDispatch();

  const data: any = location.state

  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <FDAccountDetails displayChangeStatus={false} />
    </div>
  )
}

export default FDPreliminaryUnverifiedDetail
