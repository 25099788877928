import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {FileTypesParamsModel, UpdateAccountOpeningStatus} from '../Model'
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_ACCOUNT_OPEN_LIST = `${API_URL}/customer-data`
export const UPDATE_STATUS = `${API_URL}/update-status`
export const EXPORT_MAKERLIST = `${API_URL}/customer-data/dataExport`

export const FILE_TYPES_API = `${API_URL}/get-master-data`
export const STORE_DOCUMENT_UPLOAD = `${API_URL}/store-document-upload`

export const service = {
  getAccountOpenList: (params: ParamsModel) => {
    return axios.get(GET_ACCOUNT_OPEN_LIST, {params})
  },

  updateStatus: (data: UpdateAccountOpeningStatus) => {
    return axios.post(UPDATE_STATUS, data)
  },

  exportPreliminaryVerifiedFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${EXPORT_MAKERLIST}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  getFileTypes: (params: FileTypesParamsModel) => {
    return axios.get(FILE_TYPES_API, {
      params,
    })
  },

  uploadDocument: ({
    customerId,
    fileTypeId,
    file,
  }: {
    fileTypeId: string
    customerId: string
    file: File | string
  }) => {
    const formData = new FormData()
    formData.append('customerId', customerId)
    formData.append('fileType', fileTypeId)
    formData.append('file', file)

    return axios.post(STORE_DOCUMENT_UPLOAD, formData)
  },
}
