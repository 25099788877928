import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface AccountOpenListState {
  data: {
    accountOpeningDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortVerifiedData?: AccountOpenListState[]
  customerDetailList?: {
    customerDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  uploadSuccess: boolean
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false

  customerId: ''
  // isFetchingPdf: false,
}

const InitialMakerListState: AccountOpenListState = {
  data: {
    accountOpeningDetail: [],
    meta: {},
  },
  sortVerifiedData: [],
  customerDetailList: {
    customerDetail: [],
    meta: {},
  },
  loading: false,
  success: false,
  uploadSuccess: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,

  customerId: '',
}

export const reducer = (
  state: AccountOpenListState = InitialMakerListState,
  action: ActionWithPayload<AccountOpenListState>
) => {
  switch (action.type) {
    case actionTypes.GET_ONLINEONPRIM_LIST_START: {
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
      }
    }

    case actionTypes.GET_ONLINEONPRIM_LIST_SUCCESS: {
      return {
        ...state,
        customerDetailList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ONLINEONPRIM_LIST_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }

    case actionTypes.EXPORT_ONLINEONPRIM_FILE_START: {
      return {...state}
    }

    case actionTypes.EXPORT_ONLINEONPRIM_FILE_SUCCESS: {
      return {...state, success: true}
    }

    case actionTypes.EXPORT_ONLINEONPRIM_FILE_FINISH: {
      const error = action.payload
      return {...state, error, success: false}
    }

    case actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_START: {
      return {...state, loading: true, uploadSuccess: false}
    }

    case actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_SUCCESS: {
      return {...state, loading: false, uploadSuccess: true}
    }

    case actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}

export interface IFileTypes {
  data: {
    master: [
      {
        id: string
        title: string
        code: string
        status: boolean
      }
    ]
  }
  loading: boolean
  error: any
}

const initialFileTypes: IFileTypes = {
  data: {
    master: [
      {
        id: '',
        title: '',
        code: '',
        status: false,
      },
    ],
  },
  loading: true,
  error: null,
}

export const fileTypeReducer = (
  state: IFileTypes = initialFileTypes,
  action: ActionWithPayload<IFileTypes>
) => {
  switch (action.type) {
    case actionTypes.GET_FILE_TYPES_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_FILE_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_FILE_TYPES_FINISH: {
      const error = action.payload
      return {
        ...state,
        loading: false,
        error,
      }
    }
    default:
      return state
  }
}
