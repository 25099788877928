// import { ParamsModel } from "../Model/index";
import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {AccountOpenListState} from './reducer'
import {FileTypesParamsModel} from '../Model'

export const actions = {
  getAllAccountOpenList: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_ONLINEONPRIM_LIST_START,
      payload: {params},
    }
  },

  getAllAccountOpenListSuccess: (data: AccountOpenListState) => ({
    type: actionTypes.GET_ONLINEONPRIM_LIST_SUCCESS,
    payload: data,
  }),

  getAllAccountOpenError: (data: AccountOpenListState) => ({
    type: actionTypes.GET_ONLINEONPRIM_LIST_FINISH,
    payload: data,
  }),

  //export file
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_ONLINEONPRIM_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_ONLINEONPRIM_FILE_SUCCESS,
    payload: data,
  }),

  exportFileError: () => ({
    type: actionTypes.EXPORT_ONLINEONPRIM_FILE_FINISH,
  }),

  // file types
  getFileTypes: (params: FileTypesParamsModel = {table: 'file_types'}) => ({
    type: actionTypes.GET_FILE_TYPES_START,
    payload: {params},
  }),

  getFileTypesSuccess: (data: any) => ({
    type: actionTypes.GET_FILE_TYPES_SUCCESS,
    payload: data,
  }),

  getFileTypesError: () => ({
    type: actionTypes.GET_FILE_TYPES_FINISH,
  }),

  // upload document
  uploadOnprimDocument: (data: {fileTypeId: string; file: File | string; customerId: string}) => ({
    type: actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_START,
    payload: data,
  }),

  uploadOnprimDocumentSuccess: (payload: any) => ({
    type: actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_SUCCESS,
    payload,
  }),

  uploadOnprimDocumentError: () => ({
    type: actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_FINISH,
  }),
}
