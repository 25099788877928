import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SyncAccountData, UpdateAccountOpeningStatus} from '../Model'
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_MAKERLIST = `${API_URL}/account-opening`
export const UPDATE_STATUS = `${API_URL}/update-status`
export const EXPORT_MAKERLIST = `${API_URL}/account-opening/export`
export const SYNC_ACCOUNT_DATA = `${API_URL}/send-customer-data`

export const service = {
  getMakerList: (params: ParamsModel) => {
    return axios.get(GET_MAKERLIST, {params})
  },

  updateStatus: (data: UpdateAccountOpeningStatus) => {
    return axios.post(UPDATE_STATUS, data)
  },

  exportPreliminaryVerifiedFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${EXPORT_MAKERLIST}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  syncAccountData: (data: SyncAccountData) => {
    return axios.post(SYNC_ACCOUNT_DATA, data)
  },
}
