import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface FDRequestListState {
  data: {
    fdRequestListDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortFDRequestData?: FDRequestListState[]
  customerDetailList?: {
    customerDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
}

const initialApprovedListState: FDRequestListState = {
  data: {
    fdRequestListDetail: [],
    meta: {},
  },
  sortFDRequestData: [],
  customerDetailList: {
    customerDetail: [],
    meta: {},
  },
  loading: false,
  success: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
}

export const reducer = (
  state: FDRequestListState = initialApprovedListState,
  action: ActionWithPayload<FDRequestListState>
) => {
  switch (action.type) {
    case actionTypes.GET_FD_REQUEST_LIST_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_FD_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        customerDetailList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_FD_REQUEST_LIST_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case actionTypes.GET_FD_LOG_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_FD_LOG_SUCCESS: {
      return {...state, logData: action.payload, loading: false}
    }

    case actionTypes.GET_FD_LOG_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.EXPORT_FD_REQUEST_FILE_START: {
      return {...state}
    }

    case actionTypes.EXPORT_FD_REQUEST_FILE_SUCCESS: {
      return {...state, success: true}
    }

    case actionTypes.EXPORT_FD_REQUEST_FILE_FINISH: {
      const error = action.payload
      return {...state, error, success: false}
    }

    case actionTypes.UPDATE_FD_STATUS_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_FD_STATUS_SUCCESS: {
      // const remarksData = action.payload
      return {...state, formSuccess: true, loading: false}
    }

    case actionTypes.UPDATE_FD_STATUS_FINISH: {
      const error = action.payload
      return {...state, error, formSuccess: false, loading: false}
    }

    case actionTypes.UPDATE_FD_STATUS_RESET: {
      return {...state, formSuccess: false, data: [], loading: false}
    }

    default:
      return state
  }
}
