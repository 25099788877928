import {GoogleParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
export const actions = {
  // get BankDetail
  getUserDetail: (data: GoogleParamsModel = {fromDate: '2022-09-01', toDate: '2022-09-12'}) => ({
    type: actionTypes.GET_ANALYTIC_USER_START,
    payload: data,
  }),
  getUserSuccess: (data: any) => ({
    type: actionTypes.GET_ANALYTIC_USER_SUCCESS,
    payload: data,
  }),
  getUserFinish: () => ({
    type: actionTypes.GET_ANALYTIC_USER_FINISH,
  }),

  getCountryDetail: (data: GoogleParamsModel = {fromDate: '2022-09-01', toDate: '2022-09-12'}) => ({
    type: actionTypes.GET_COUNTRY_USER_START,
    payload: data,
  }),
  getCountryDetailSuccess: (data: any) => ({
    type: actionTypes.GET_COUNTRY_USER_SUCCESS,
    payload: data,
  }),
  getCountryDetailFinish: () => ({
    type: actionTypes.GET_COUNTRY_USER_FINISH,
  }),

  getUniqueDetail: (data: GoogleParamsModel = {fromDate: '2022-09-01', toDate: '2022-09-12'}) => ({
    type: actionTypes.GET_UNIQUE_USER_START,
    payload: data,
  }),
  getUniqueDetailSuccess: (data: any) => ({
    type: actionTypes.GET_UNIQUE_USER_SUCCESS,
    payload: data,
  }),
  getUniqueDetailFinish: () => ({
    type: actionTypes.GET_UNIQUE_USER_FINISH,
  }),
}
