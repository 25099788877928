import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import {useEffect, useState} from 'react'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {isEmpty} from 'lodash'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import * as yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import * as fdProductManagerRedux from 'src/app/modules/fdProductManagement/fdProductManager/redux'
import {toast} from 'react-toastify'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import * as tenureRedux from '../../tenure/redux'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType?: string
  editSelectedData?: any
}

function convertToSlug(text: any) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const AddFDProductManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [attachedFile, setAttachedFile] = useState('')
  const [attachfile, setAttachfile] = useState('')

  const {loading, success} = useSelector((state: any) => state.fdProductManager)

  const {
    data: {tenure},
  } = useSelector((state: any) => state.tenureManager)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const TenuresOption = tenure?.map((item: any) => {
    return {
      label: item?.title,
      value: item?.id,
    }
  })

  useEffect(() => {
    dispatch(tenureRedux.actions.getAllTenure())
  }, [])

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('FD Product manager added successfully')
        : toast.success('FD Product manager edited successfully')
      dispatch(fdProductManagerRedux.actions.getAllFDProductManager(params))
      dispatch(fdProductManagerRedux.actions.resetFDProductManager())
      handleClose()
    }
  }, [success])

  const validationSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    // slug: yup.string().required("Slug is required"),

    // minimumBalance: yup.string().required("Minimum balance is required"),
    // paymentFrequency: yup.string().required("Payment frequency is required"),
    // description: yup.string().required("Description is required"),
    // first_button: yup.string().required("First button name is required"),
    // first_button_link: yup.string().required("First button link is required")
  })
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>{actionType} Product Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                minimumBalance: '',
                paymentFrequency: '',
                description: '',
                first_button: '',
                first_button_link: '',
                image: '',
                interestRateTenures: [{tenureId: '', interestRate: '', accType: ''}],
                status: '',
              }}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                  interestRateTenures: values?.interestRateTenures?.map(
                    (item: {[key: string]: string}) => {
                      return {
                        ...item,
                        tenureId: item?.tenureId,
                        interestRate: item?.interestRate,
                        acType: item?.acType,
                        id: !isEmpty(editSelectedData) ? item.id : null,
                      }
                    }
                  ),
                  status: values?.status === 'Active',
                }
                const formEditData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                  interestRateTenures: values?.interestRateTenures?.map(
                    (item: {[key: string]: any}) => {
                      console.log(item)
                      return {
                        id: !isEmpty(editSelectedData) ? item.id : null,
                        tenureId: item?.tenureId || item?.tenure?.id,
                        acType: item?.acType,
                        interestRate: item?.interestRate,
                      }
                    }
                  ),

                  status: values?.status === 'Active',
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    fdProductManagerRedux.actions.updateFDProductManager(
                      formEditData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(fdProductManagerRedux?.actions?.addFDProductManager(formData))
                }
              }}
              validationSchema={validationSchema}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'title',
                      'slug',
                      'minimumBalance',
                      'paymentFrequency',
                      'description',
                      'first_button',
                      'first_button_link',
                      'image',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'interestRateTenures',
                      editSelectedData?.interestRateTenure?.interestRateTenure,
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            label='Title'
                            onKeyUp={(event: any) => {
                              if (isEmpty(editSelectedData)) {
                                const val = convertToSlug(event.target.value)
                                setFieldValue('slug', val, false)
                              }
                            }}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Slug'
                            disabled
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Minimum Balance'
                            name='minimumBalance'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Minimum Balance'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Payment Frequency'
                            name='paymentFrequency'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Payment Frequency'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Name'
                            name='first_button'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Name'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Link'
                            name='first_button_link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Link'
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Attached File'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.image}
                          />
                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('image', '')
                                    }}
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.image.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.image}`
                                    }
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className='row'>
                        <FormTinyMce
                          containerClassName='col-md-12'
                          label='Description'
                          name='description'
                          initialValue={
                            !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                          }
                          handleChange={handleChange}
                        />
                      </div>

                      <div className='row pt-5 border-top'>
                        <h2>Interest Rate</h2>
                        <div className='row'>
                          <FieldArray
                            name='interestRateTenures'
                            render={(arrayHelpers) => (
                              <div>
                                {values?.interestRateTenures &&
                                values?.interestRateTenures?.length > 0
                                  ? values?.interestRateTenures?.map((friend: any, index: any) => (
                                      <div className='row' key={index}>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormSelect
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Select Tenure'
                                            label='Tenure'
                                            name={`interestRateTenures.${index}.tenureId`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            options={TenuresOption}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            arrValue={
                                              !isEmpty(editSelectedData) &&
                                              !values?.interestRateTenures[index]?.tenureId
                                                ? editSelectedData?.interestRateTenure
                                                    ?.interestRateTenure[index]?.tenure?.id
                                                : values?.interestRateTenures[index].tenureId
                                            }
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Interest Rate'
                                            name={`interestRateTenures.${index}.interestRate`}
                                            label='Interest Rate'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Account Type'
                                            name={`interestRateTenures.${index}.acType`}
                                            label='Account Type'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>

                                        {index > 0 && (
                                          <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}
                                {values?.interestRateTenures?.length == 10 ? null : (
                                  <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                    <button
                                      className='p-2 ps-5 pe-5 btn btn-primary'
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.push({
                                          id: null,
                                          tenureId: '',
                                          interestRate: '',
                                        })
                                      }
                                    >
                                      + Add More
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>

                      <div className='col-md-12 mt-5 col-xs-12'>
                        <label className='form-label fw-bolder text-dark fs-6 required'>
                          Status
                        </label>

                        <div className='d-flex ms-5'>
                          {statusOptions?.map((status: {label: string; value: string}) => (
                            <FormRadio
                              containerClassName=''
                              label='Select Status'
                              name='status'
                              type='radio'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                              checkBoxText={status?.label}
                              value={status?.value}
                            />
                          ))}
                        </div>
                        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                          <ErrorMessage
                            name='status'
                            component='div'
                            className='field-error-message'
                          />
                        </div>
                      </div>

                      <div className='d-flex justify-content-end px-9'>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn-sm ms-3'
                        >
                          Save
                        </button>
                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddFDProductManager
