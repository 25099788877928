import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {GrView} from 'react-icons/gr'
import {IconButton, Pagination, Tooltip, Whisper} from 'rsuite'
import Table from 'rsuite/Table'
import {useHistory} from 'react-router-dom'
import * as fdApprovedListRedux from 'src/app/modules/fdRequest/fdApproved/redux'

const Cell = Table.Cell

const FdApprovedList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  const [params, setParams] = useState<StateParamsModel>({
    status: 'FD Account Opened',
    page: 1,
    limit: 10,
  })
  const fdApprovedListData: fdApprovedListRedux.AccountFdApprovedState = useSelector(
    (state: any) => {
      return state?.fdApprovedList
    }
  )

  const {loading} = fdApprovedListData

  const data =
    fdApprovedListData?.customerDetailList?.customerDetail?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      customerId: item?.customerId,
      firstName: item?.firstName,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleGetAllData = () => {
    setParams({...params, limit: fdApprovedListData?.data?.meta?.total})
    dispatch(fdApprovedListRedux.actions.getAllFdApprovedList(params))
  }

  useEffect(() => {
    dispatch(fdApprovedListRedux.actions.getAllFdApprovedList(params))
  }, [params])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              history.push({
                pathname: `/fd-request/fd-approved/${rowData.id}`,
                state: rowData,
              })
            }}
            icon={<GrView size={18} />}
          />
        </Whisper>
      </Cell>
    )
  }

  //table columns
  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },

    {
      label: 'Reference No.',
      dataKey: 'newAccountNumber',
      cell: <Cell dataKey='newAccountNumber' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Customer Id',
      dataKey: 'requestCode',
      cell: <Cell dataKey='requestCode' />,
      sortable: false,
      width: 230,
    },

    {
      label: 'Name',
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Email',
      dataKey: 'email',
      cell: <Cell dataKey='email' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      width: 230,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },

    {
      label: 'Action',
      width: 100,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleRefresh = () => {
    dispatch(fdApprovedListRedux.actions.getAllFdApprovedList(params))
  }

  const handleExport = () => {
    dispatch(fdApprovedListRedux.actions.exportFile('FdApproved List Data', params))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Fd Approved List'
          params={params}
          setParams={setParams}
          addShow={false}
          deleteShow={false}
          filterData={true}
          handleRefresh={handleRefresh}
          importShow={false}
          exportShow={true}
          toggleMultipleShow={false}
          data={data}
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
          handleExport={handleExport}
        />
        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={fdApprovedListData?.loading}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={fdApprovedListData?.customerDetailList?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

export default FdApprovedList
