import {imageBaseUrl, onlineBaseUrl} from 'src/cms/helpers/constants'

interface ImagePreviewProps {
  label?: string
  imageSrc: string
}

const ImagePreview = ({imageSrc, label}: ImagePreviewProps) => {
  return (
    <>
      <label>{label}</label>
      {imageSrc && (
        <button
          onClick={(e) => {
            if (imageSrc) {
              e.preventDefault()
              window.open(`${onlineBaseUrl}/${imageSrc}`)
            }
            // window.open(`${imageBaseUrl}/${imageSrc}`)
          }}
        >
          View
        </button>
      )}
    </>
  )
}

export default ImagePreview
