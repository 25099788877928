export const actionTypes = {
  //get preliminary verified
  GET_FD_REQUEST_LIST_START: 'GET_FD_REQUEST_LIST_START',
  GET_FD_REQUEST_LIST_SUCCESS: 'GET_FD_REQUEST_LIST_SUCCESS',
  GET_FD_REQUEST_LIST_FINISH: 'GET_FD_REQUEST_LIST_FINISH',

  //get Export file
  EXPORT_FD_REQUEST_FILE_START: 'EXPORT_FD_REQUEST_FILE_START',
  EXPORT_FD_REQUEST_FILE_SUCCESS: 'EXPORT_FD_REQUEST_FILE_SUCCESS',
  EXPORT_FD_REQUEST_FILE_FINISH: 'EXPORT_FD_REQUEST_FILE_FINISH',

  // get get Log
  GET_FD_LOG_START: 'GET_FD_LOG_START',
  GET_FD_LOG_SUCCESS: 'GET_FD_LOG_SUCCESS',
  GET_FD_LOG_FINISH: 'GET_FD_LOG_FINISH',

  //Add Remarks
  UPDATE_FD_STATUS_REQUEST: 'UPDATE_FD_STATUS_REQUEST',
  UPDATE_FD_STATUS_SUCCESS: 'UPDATE_FD_STATUS_SUCCESS',
  UPDATE_FD_STATUS_FINISH: 'UPDATE_FD_STATUS_FINISH',
  UPDATE_FD_STATUS_RESET: 'UPDATE_FD_STATUS_RESET',
}
