import { useEffect, useState } from "react"
import Citizenship from "../Citizenship"
import Other from "../Other"
import Passport from "../Passport"
import SingleColumn from "../SingleColumn"

type Props = {
    guardianIdentificationInformation?: any,
    documents?: any
}

const GuardianIdentificationData = ({ guardianIdentificationInformation, documents }: Props) => {
    const [fileURL, setFileURL] = useState({
        file: "",
        front: "",
        back: ""
    });
    const [idType, setIdType] = useState("");

    useEffect(() => {
        if (idType === "Citizenship") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Guardian Citizenship")
            setFileURL({
                file: "",
                front: file?.fileFront,
                back: file?.fileBack
            });
        }
        if (idType === "Passport") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Guardian Passport")
            setFileURL({
                file: file?.file,
                front: file?.fileFront,
                back: file?.fileBack
            });
        }
        if (idType === "Others") {
            const file = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Guardian Other")
            setFileURL({
                file: file?.file,
                front: file?.fileFront,
                back: file?.fileBack
            });
        }

    }, [documents, idType])

    useEffect(() => {
        setIdType(guardianIdentificationInformation?.idType?.title)
    }, [guardianIdentificationInformation])

    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Guardian Identification Details</div>
            <div className="row">
                <SingleColumn label="Identification Type" value={idType} />
            </div>
            <div className="row">
                {
                    {
                        Citizenship: <Citizenship data={guardianIdentificationInformation} frontURL={fileURL.front} backURL={fileURL.back} />,
                        Passport: <Passport data={guardianIdentificationInformation} fileURL={fileURL.file} />,
                        Others: <Other data={guardianIdentificationInformation} fileURL={fileURL.file} />,
                    }[idType]
                }
            </div>
        </>
    )
}

export default GuardianIdentificationData