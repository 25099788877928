import {ParamsModel} from 'src/app/modules/common/Model'
import {FDProductManagerModel, SortFDProductManagerModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllFDProductManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALL_FD_PRODUCT_MANAGER_START,
    payload: {params},
  }),

  getAllFDProductManagerSuccess: (data: FDProductManagerModel | any) => ({
    type: actionTypes.GET_ALL_FD_PRODUCT_MANAGER_SUCCESS,
    payload: data,
  }),

  getAllFDProductManagerError: (data: any) => ({
    type: actionTypes.GET_ALL_FD_PRODUCT_MANAGER_FINISH,
    payload: data,
  }),

  //Enable FDProductManager
  enableFDProductManager: (data: {id: string}[]) => ({
    type: actionTypes.ENABLE_FD_PRODUCT_MANAGER_REQUEST,
    payload: {data},
  }),

  enableFDProductManagerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: task,
  }),
  enableFDProductManagerFinish: () => ({
    type: actionTypes.ENABLE_FD_PRODUCT_MANAGER_FINISH,
  }),

  //Disable FDProductManager
  disableFDProductManager: (data: {id: string}[]) => ({
    type: actionTypes.DISABLE_FD_PRODUCT_MANAGER_REQUEST,
    payload: {data},
  }),

  disableFDProductManagerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: task,
  }),
  disableFDProductManagerFinish: () => ({
    type: actionTypes.DISABLE_FD_PRODUCT_MANAGER_FINISH,
  }),

  singleDisableFDProductManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_DISABLE_FD_PRODUCT_MANAGER_REQUEST,
    payload: {data},
  }),

  singleDisableFDProductManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: task,
  }),

  singleDisableFDProductManagerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_FD_PRODUCT_MANAGER_FINISH,
  }),

  singleEnableFDProductManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_ENABLE_FD_PRODUCT_MANAGER_REQUEST,
    payload: {data},
  }),

  singleEnableFDProductManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: task,
  }),

  singleEnableFDProductManagerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_FD_PRODUCT_MANAGER_FINISH,
  }),

  deleteFDProductManager: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_FD_PRODUCT_MANAGER_START,
    payload: {fdProductManagerId: data},
  }),

  deleteFDProductManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: data,
  }),

  deleteFDProductManagerFinish: () => ({
    type: actionTypes.DELETE_FD_PRODUCT_MANAGER_FINISH,
  }),

  addFDProductManager: (data: FDProductManagerModel | any) => ({
    type: actionTypes.ADD_FD_PRODUCT_MANAGER_START,
    payload: data,
  }),

  addFDProductManagerSuccess: (data: FDProductManagerModel | any) => ({
    type: actionTypes.ADD_FD_PRODUCT_MANAGER_SUCCESS,
    payload: data,
  }),

  addFDProductManagerFinish: () => ({
    type: actionTypes.ADD_FD_PRODUCT_MANAGER_FINISH,
  }),

  resetFDProductManager: () => ({
    type: actionTypes.RESET_FD_PRODUCT_MANAGER,
  }),

  updateFDProductManager: (data: FDProductManagerModel | any, id: string) => ({
    type: actionTypes.UPDATE_FD_PRODUCT_MANAGER_START,
    payload: data,
    id,
  }),

  updateFDProductManagerSuccess: (data: any) => ({
    type: actionTypes.UPDATE_FD_PRODUCT_MANAGER_SUCCESS,
    payload: data,
  }),

  updateFDProductManagerFinish: () => ({
    type: actionTypes.UPDATE_FD_PRODUCT_MANAGER_FINISH,
  }),

  sortFDProductManager: (data: SortFDProductManagerModel) => ({
    type: actionTypes.SORT_FD_PRODUCT_MANAGER_START,
    payload: data,
  }),

  sortFDProductManagerSuccess: (data: Array<FDProductManagerModel>) => ({
    type: actionTypes.SORT_FD_PRODUCT_MANAGER_SUCCESS,
    payload: data,
  }),

  sortFDProductManagerFinish: () => ({
    type: actionTypes.SORT_FD_PRODUCT_MANAGER_FINISH,
  }),

  sortFDProductManagerReset: () => ({
    type: actionTypes.SORT_FD_PRODUCT_MANAGER_RESET,
  }),
}
