import FDAccountDetails from 'src/app/modules/common/components/FDInformation'

const FdAccountOpeningRejectedList = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <FDAccountDetails displayChangeStatus={false} />
    </div>
  )
}

export default FdAccountOpeningRejectedList
