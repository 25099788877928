import {ParamsModel} from 'src/app/modules/common/Model'
import {TermAndConditionModel, SortTermAndConditionModel} from '../Model/TermAndConditionModel'
import {actionTypes} from './constants'
export const actions = {
  // get TermAndCondition
  getAllTermAndCondition: () => ({
    type: actionTypes.GET_ALL_TERM_AND_CONDITION_START,
  }),
  getAllTermAndConditionSuccess: (data: TermAndConditionModel | any) => ({
    type: actionTypes.GET_ALL_TERM_AND_CONDITION_SUCCESS,
    payload: data,
  }),
  getAllTermAndConditionFinish: () => ({
    type: actionTypes.GET_ALL_TERM_AND_CONDITION_FINISH,
  }),

  // get TermAndCondition DATA
  getTermAndCondition: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_TERM_AND_CONDITION_START,
    payload: params,
  }),
  getTermAndConditionSuccess: (data: TermAndConditionModel | any) => ({
    type: actionTypes.GET_TERM_AND_CONDITION_SUCCESS,
    payload: data,
  }),
  getTermAndConditionFinish: () => ({
    type: actionTypes.GET_TERM_AND_CONDITION_FINISH,
  }),

  // create key
  addTermAndCondition: (data: TermAndConditionModel | any) => ({
    type: actionTypes.ADD_TERM_AND_CONDITION_START,
    payload: data,
  }),
  addTermAndConditionSuccess: (task: any) => ({
    type: actionTypes.ADD_TERM_AND_CONDITION_SUCCESS,
    payload: task,
  }),
  addTermAndConditionFinish: () => ({
    type: actionTypes.ADD_TERM_AND_CONDITION_FINISH,
  }),
  resetTermAndCondition: () => ({
    type: actionTypes.RESET_PROGRAM_TAG,
  }),

  //Update TermAndCondition
  updateTermAndCondition: (data: TermAndConditionModel | any, id: string) => ({
    type: actionTypes.UPDATE_TERM_AND_CONDITION_START,
    payload: data,
    id,
  }),

  updateTermAndConditionSuccess: (data: TermAndConditionModel | any) => ({
    type: actionTypes.UPDATE_TERM_AND_CONDITION_SUCCESS,
    payload: data,
  }),

  updateTermAndConditionFinish: () => ({
    type: actionTypes.UPDATE_TERM_AND_CONDITION_FINISH,
  }),

  // delete key
  deleteTermAndCondition: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_TERM_AND_CONDITION_START,
    payload: {termAndConditionId: data},
  }),
  deleteTermAndConditionSuccess: (data: any) => ({
    type: actionTypes.DELETE_TERM_AND_CONDITION_SUCCESS,
    payload: data,
  }),
  deleteTermAndConditionFinish: () => ({
    type: actionTypes.DELETE_TERM_AND_CONDITION_FINISH,
  }),

  //Enable TermAndCondition
  enableTermAndCondition: (data: any) => ({
    type: actionTypes.ENABLE_TERM_AND_CONDITION_REQUEST,
    payload: {data},
  }),

  enableTermAndConditionSuccess: (task: any) => ({
    type: actionTypes.ENABLE_TERM_AND_CONDITION_SUCCESS,
    payload: task,
  }),
  enableTermAndConditionFinish: () => ({
    type: actionTypes.ENABLE_TERM_AND_CONDITION_FINISH,
  }),

  //Disable TermAndCondition
  disableTermAndCondition: (data: any) => ({
    type: actionTypes.DISABLE_TERM_AND_CONDITION_REQUEST,
    payload: {data},
  }),

  disableTermAndConditionSuccess: (task: any) => ({
    type: actionTypes.DISABLE_TERM_AND_CONDITION_SUCCESS,
    payload: task,
  }),
  disableTermAndConditionFinish: () => ({
    type: actionTypes.DISABLE_TERM_AND_CONDITION_FINISH,
  }),

  //Enable TermAndCondition
  singleEnableTermAndCondition: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_TERM_AND_CONDITION_REQUEST,
    payload: {data},
  }),

  singleEnableTermAndConditionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_TERM_AND_CONDITION_SUCCESS,
    payload: task,
  }),
  singleEnableTermAndConditionFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_TERM_AND_CONDITION_FINISH,
  }),

  //Disable TermAndCondition
  singleDisableTermAndCondition: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_TERM_AND_CONDITION_REQUEST,
    payload: {data},
  }),

  singleDisableTermAndConditionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_TERM_AND_CONDITION_SUCCESS,
    payload: task,
  }),
  singleDisableTermAndConditionFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_TERM_AND_CONDITION_FINISH,
  }),

  // sort
  sortTermAndCondition: (data: SortTermAndConditionModel) => ({
    type: actionTypes.SORT_TERM_AND_CONDITION_START,
    payload: data,
  }),
  sortTermAndConditionSuccess: (data: Array<TermAndConditionModel>) => ({
    type: actionTypes.SORT_TERM_AND_CONDITION_SUCCESS,
    payload: data,
  }),
  sortTermAndConditionFinish: () => ({
    type: actionTypes.SORT_TERM_AND_CONDITION_FINISH,
  }),
  sortTermAndConditionReset: () => ({
    type: actionTypes.SORT_TERM_AND_CONDITION_RESET,
  }),
}
