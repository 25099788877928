import { useEffect, useState } from "react";
import ImagePreview from "../ImagePreview";

type Props = {
    documents?: any
}

export default function Signature({ documents }: Props) {
    const [signatureURL, setSignatureURL] = useState("");

    useEffect(() => {
        const sign = documents?.find((element: any) => element?.fileType?.code === "identity" && element?.fileType?.title === "Signature");
        setSignatureURL(sign?.file);
    }, [documents])
    return (
        <div className="row">
            <div className="mb-1 text-primary fs-5 fw-bold">Signature</div>
            <div className="col-12">
                <div className="row">
                    <div className='mb-3 col-12 col-md-6 col-lg-4'>
                        <ImagePreview imageSrc={signatureURL} label="Signature" />
                    </div>
                </div>
            </div>
        </div>
    )
}