import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {
  TermAndConditionModel,
  SortTermAndConditionActionModel,
  SortTermAndConditionModel,
} from '../Model/TermAndConditionModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getTermAndConditionSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getTermAndCondition, params)
    yield put(actions.getTermAndConditionSuccess(response?.data?.data))
    yield put(actions.getTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getTermAndConditionFinish())
  }
}

function* getAllTermAndConditionSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllTermAndCondition)
    yield put(actions.getAllTermAndConditionSuccess(response?.data?.data))
    yield put(actions.getAllTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllTermAndConditionFinish())
  }
}

function* addTermAndConditionSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addTermAndCondition, body)
    yield put(actions.addTermAndConditionSuccess(response.data?.data))
    yield put(actions.addTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addTermAndConditionFinish())
  }
}

function* enableTermAndConditionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableTermAndCondition, selectedUsers)
    yield put(actions.enableTermAndConditionSuccess(response?.data))
    yield put(actions.enableTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableTermAndConditionFinish())
  }
}

function* disableTermAndConditionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableTermAndCondition, selectedUsers)
    yield put(actions.disableTermAndConditionSuccess(response?.data))
    yield put(actions.disableTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableTermAndConditionFinish())
  }
}

function* singleEnableTermAndConditionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableTermAndCondition, selectedUsers)
    yield put(actions.singleEnableTermAndConditionSuccess(response?.data))
    yield put(actions.singleEnableTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableTermAndConditionFinish())
  }
}

function* singleDisableTermAndConditionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableTermAndCondition, selectedUsers)
    yield put(actions.singleDisableTermAndConditionSuccess(response?.data))
    yield put(actions.singleDisableTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableTermAndConditionFinish())
  }
}

function* updateTermAndConditionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateTermAndCondition,
      body,
      action.payload?.id
    )
    yield put(actions.updateTermAndConditionSuccess(response.data?.data))
    yield put(actions.updateTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateTermAndConditionFinish())
  }
}

function* deleteTermAndConditionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteTermAndCondition, body)
    yield put(actions.deleteTermAndConditionSuccess(response.data?.data))
    yield put(actions.deleteTermAndConditionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteTermAndConditionFinish())
  }
}

function* sortTermAndCondition(action: SortTermAndConditionActionModel) {
  try {
    const body: SortTermAndConditionModel = action.payload
    const response: ResponseModel = yield call(service.sortTermAndCondition, body)

    yield put({
      type: actionTypes.SORT_TERM_AND_CONDITION_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_TERM_AND_CONDITION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_TERM_AND_CONDITION_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_TERM_AND_CONDITION_START, getTermAndConditionSaga)
  yield takeLatest(actionTypes.GET_ALL_TERM_AND_CONDITION_START, getAllTermAndConditionSaga)
  yield takeLatest(actionTypes.ADD_TERM_AND_CONDITION_START, addTermAndConditionSaga)
  yield takeLatest(actionTypes.UPDATE_TERM_AND_CONDITION_START, updateTermAndConditionSaga)
  yield takeLatest(actionTypes.DELETE_TERM_AND_CONDITION_START, deleteTermAndConditionSaga)
  yield takeLatest(actionTypes.ENABLE_TERM_AND_CONDITION_REQUEST, enableTermAndConditionSaga)
  yield takeLatest(actionTypes.DISABLE_TERM_AND_CONDITION_REQUEST, disableTermAndConditionSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_TERM_AND_CONDITION_REQUEST,
    singleEnableTermAndConditionSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_TERM_AND_CONDITION_REQUEST,
    singleDisableTermAndConditionSaga
  )
  yield takeLatest(actionTypes.SORT_TERM_AND_CONDITION_START, sortTermAndCondition)
}
