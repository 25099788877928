import {useEffect, useState} from 'react'
import Citizenship from '../Citizenship'
import Other from '../Other'
import Passport from '../Passport'
import SingleColumn from '../SingleColumn'
import ImagePreview from '../ImagePreview'

type Props = {
  hasNominee?: boolean
  nomineeData?: any
  documents?: any
}

const NomineeDetail = ({hasNominee, nomineeData, documents}: Props) => {
  const [identificationType, setIdentificationType] = useState('')
  const [fileURL, setFileURL] = useState({
    front: '',
    back: '',
    file: '',
  })
  const [nomineePhoto, setNomineePhoto] = useState('')

  useEffect(() => {
    setIdentificationType(nomineeData?.identificationType?.title)
  }, [nomineeData])

  useEffect(() => {
    if (identificationType === 'Citizenship') {
      const file = documents?.find(
        (element: any) =>
          element?.fileType?.code === 'other' && element?.fileType?.title === 'Citizenship'
      )
      setFileURL({
        file: '',
        front: file?.fileFront,
        back: file?.fileBack,
      })
    }
    if (identificationType === 'Passport') {
      const file = documents?.find(
        (element: any) =>
          element?.fileType?.code === 'other' && element?.fileType?.title === 'Passport'
      )
      setFileURL({
        file: file?.file,
        front: file?.fileFront,
        back: file?.fileBack,
      })
    }
    if (identificationType === 'Others') {
      const file = documents?.find(
        (element: any) =>
          element?.fileType?.code === 'other' && element?.fileType?.title === 'Other'
      )
      setFileURL({
        file: file?.file,
        front: file?.fileFront,
        back: file?.fileBack,
      })
    }

    const nomineeFile = documents?.find(
      (element: any) =>
        element?.fileType?.code === 'other' && element?.fileType?.title === 'Nominee Passport Photo'
    )
    setNomineePhoto(nomineeFile?.file)
  }, [identificationType, documents])

  return (
    <>
      <div className='row'>
        <SingleColumn label='Nominee Name' value={nomineeData?.name} />
        <SingleColumn label='Relation to nominee' value={nomineeData?.relation?.title} />
        {nomineeData?.relation?.title === 'Other' && (
          <SingleColumn label='Relation to nominee' value={nomineeData?.otherRelation} />
        )}
        <SingleColumn label='Date of Birth (B.S)' value={nomineeData?.dateOfBirthBs} />
        <SingleColumn label='Date of Birth (A.D)' value={nomineeData?.dateOfBirthAd} />
        <SingleColumn label='Contact No.' value={nomineeData?.contactNumber} />
        <SingleColumn label='Father/Mother Name' value={nomineeData?.fatherMotherName} />
        <div className='col-12'>
          <div className='row'>
            <div className='mb-3 col-12 col-md-6 col-lg-4'>
              <ImagePreview imageSrc={nomineePhoto} label='Nominee Photo' />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='mb-1 text-primary fs-5 fw-bold'>Nominee Identification Details</div>
        <SingleColumn
          label='Nominee Identification Type'
          value={nomineeData?.identificationType?.title}
        />
        {
          {
            Citizenship: (
              <Citizenship data={nomineeData} frontURL={fileURL.front} backURL={fileURL.back} />
            ),
            Passport: <Passport data={nomineeData} fileURL={fileURL.file} />,
            Others: <Other data={nomineeData} fileURL={fileURL.file} />,
          }[identificationType]
        }
      </div>
    </>
  )
}

export default NomineeDetail
