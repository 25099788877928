export const actionTypes = {
    //get preliminary verified
    GET_PRELIMINARY_UNVERIFIED_START: 'GET_PRELIMINARY_UNVERIFIED_START',
    GET_PRELIMINARY_UNVERIFIED_SUCCESS: 'GET_PRELIMINARY_UNVERIFIED_SUCCESS',
    GET_PRELIMINARY_UNVERIFIED_FINISH: 'GET_PRELIMINARY_UNVERIFIED_FINISH',

    //get Export file
    EXPORT_UNVERIFIED_FILE_START: 'EXPORT_UNVERIFIED_FILE_START',
    EXPORT_UNVERIFIED_FILE_SUCCESS: 'EXPORT_UNVERIFIED_FILE_SUCCESS',
    EXPORT_UNVERIFIED_FILE_FINISH: 'EXPORT_UNVERIFIED_FILE_FINISH',

    //resend otp code
    RESEND_PRELIMINARY_OTP_CODE_START: 'RESEND_PRELIMINARY_OTP_CODE_START',
    RESEND_PRELIMINARY_OTP_CODE_SUCCESS: 'RESEND_PRELIMINARY_OTP_CODE_SUCCESS',
    RESEND_PRELIMINARY_OTP_CODE_FINISH: 'RESEND_PRELIMINARY_OTP_CODE_FINISH',
}