import axios from 'axios'
import { ParamsModel } from 'src/app/modules/common/Model'
import { ProductManagerModel, SortProductManagerModel } from '../Model'
// import { ProductManagerModel } from '../Model/ProductManagerModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PRODUCT_MANAGER_DATA = `${API_URL}/product-manager`

export const service = {
    getAllProductManager: (params: ParamsModel) => {
        return axios.get(PRODUCT_MANAGER_DATA, { params });
    },

    enableProductManager: (data: Array<string>) => {
        const productManager = {
            productManagerId: data
        }
        return axios.patch(`${PRODUCT_MANAGER_DATA}/enable`, productManager)
    },

    disableProductManager: (data: Array<string>) => {
        const productManager = {
            productManagerId: data
        }
        return axios.patch(`${PRODUCT_MANAGER_DATA}/disable`, productManager)
    },

    singleEnableProductManager: (data: Array<string>) => {
        const selectedProductManager = {
            productManagerId: [data],
        }
        return axios.patch(`${PRODUCT_MANAGER_DATA}/enable`, selectedProductManager)
    },

    singleDisableProductManager: (data: Array<string>) => {
        const selectedProductManager = {
            productManagerId: [data],
        }
        return axios.patch(`${PRODUCT_MANAGER_DATA}/disable`, selectedProductManager)
    },

    deleteProductManager: (data: { id: string }) => {
        return axios.delete(PRODUCT_MANAGER_DATA, { data });
    },

    addProductManager: (data: any) => {
        return axios.post(PRODUCT_MANAGER_DATA, data);
    },

    updateProductManager: (data: ProductManagerModel, id: string) => {
        return axios.patch(`${PRODUCT_MANAGER_DATA}/${id}`, data)
    },

    sortProductManager: (body: SortProductManagerModel) => {
        return axios.patch(`${PRODUCT_MANAGER_DATA}/sort`, body)
    },
}