import SingleColumn from "../SingleColumn"
import NomineeDetail from "./nominee"

type Props = {
    nominee: any,
    hasNominee?: boolean,
    documents?: any
}

const NomineeList = ({ nominee, hasNominee, documents }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Nominee Detail</div>
            <div className="row">
                <SingleColumn label="Has Nominee" value={hasNominee ? "Yes" : "No"} />
            </div>
            {hasNominee && nominee?.map((element: any, index: number) => (
                <NomineeDetail nomineeData={element} key={index} documents={documents} />
            ))}
        </>
    )
}

export default NomineeList