import { Action } from "redux";
import { actionTypes } from "./constants";

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export interface PreVerifiedState {
    data: {
        // customerDetail: { [key: string]: number | string }[]
        preliminaryUnverifiedDetail: { [key: string]: number | string }[]
        meta: { [key: string]: number }
    }
    sortVerifiedData?: PreVerifiedState[]
    customerDetailList?: { customerDetail: any[] | any }
    loading: false
    success: false
    editSuccess: false
    activateSuccess: false
    deactivateSuccess: false
    deleteSuccess: false
    toggleLoading: false
    singleActivateSuccess: false
    singleDeactivateSuccess: false,
    resendOtpSuccess: false
}

const initialPreVerifiedState: PreVerifiedState = {
    data: {
        // customerDetail: [],
        preliminaryUnverifiedDetail: [],
        meta: {},
    },
    sortVerifiedData: [],
    customerDetailList: { customerDetail: [] },
    loading: false,
    success: false,
    editSuccess: false,
    activateSuccess: false,
    deactivateSuccess: false,
    deleteSuccess: false,
    toggleLoading: false,
    singleActivateSuccess: false,
    singleDeactivateSuccess: false,
    resendOtpSuccess: false
}

export const reducer = (
    state: PreVerifiedState = initialPreVerifiedState,
    action: ActionWithPayload<PreVerifiedState>
) => {
    switch (action.type) {
        case actionTypes.GET_PRELIMINARY_UNVERIFIED_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.GET_PRELIMINARY_UNVERIFIED_SUCCESS: {
            return {
                ...state,
                data: action.payload?.data,
                loading: false
            }
        }

        case actionTypes.GET_PRELIMINARY_UNVERIFIED_FINISH: {
            const error = action.payload
            return {
                ...state,
                error,
                loading: false
            }
        }

        case actionTypes.EXPORT_UNVERIFIED_FILE_START: {
            return { ...state }
        }

        case actionTypes.EXPORT_UNVERIFIED_FILE_SUCCESS: {
            return { ...state, success: true }
        }

        case actionTypes.EXPORT_UNVERIFIED_FILE_FINISH: {
            const error = action.payload
            return { ...state, error, success: false }
        }

        case actionTypes.RESEND_PRELIMINARY_OTP_CODE_START: {
            return { ...state }
        }

        case actionTypes.RESEND_PRELIMINARY_OTP_CODE_SUCCESS: {
            return {
                ...state,
                resendOtpSuccess: true
            }
        }

        case actionTypes.RESEND_PRELIMINARY_OTP_CODE_FINISH: {
            return {
                ...state,
                resendOtpSuccess: false
            }
        }

        default:
            return state
    }
}
