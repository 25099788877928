import {ParamsModel} from 'src/app/modules/common/Model'
import {TenureModel, SortTenureModel} from '../Model/TenureModel'
import {actionTypes} from './constants'
export const actions = {
  // get Tenure
  getAllTenure: () => ({
    type: actionTypes.GET_ALL_TENURE_START,
  }),
  getAllTenureSuccess: (data: TenureModel | any) => ({
    type: actionTypes.GET_ALL_TENURE_SUCCESS,
    payload: data,
  }),
  getAllTenureFinish: () => ({
    type: actionTypes.GET_ALL_TENURE_FINISH,
  }),

  // get Tenure DATA
  getTenure: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_TENURE_START,
    payload: params,
  }),
  getTenureSuccess: (data: TenureModel | any) => ({
    type: actionTypes.GET_TENURE_SUCCESS,
    payload: data,
  }),
  getTenureFinish: () => ({
    type: actionTypes.GET_TENURE_FINISH,
  }),

  // create key
  addTenure: (data: TenureModel | any) => ({
    type: actionTypes.ADD_TENURE_START,
    payload: data,
  }),
  addTenureSuccess: (task: any) => ({
    type: actionTypes.ADD_TENURE_SUCCESS,
    payload: task,
  }),
  addTenureFinish: () => ({
    type: actionTypes.ADD_TENURE_FINISH,
  }),
  resetTenure: () => ({
    type: actionTypes.RESET_PROGRAM_TAG,
  }),

  //Update Tenure
  updateTenure: (data: TenureModel | any, id: string) => ({
    type: actionTypes.UPDATE_TENURE_START,
    payload: data,
    id,
  }),

  updateTenureSuccess: (data: TenureModel | any) => ({
    type: actionTypes.UPDATE_TENURE_SUCCESS,
    payload: data,
  }),

  updateTenureFinish: () => ({
    type: actionTypes.UPDATE_TENURE_FINISH,
  }),

  // delete key
  deleteTenure: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_TENURE_START,
    payload: {tenureId: data},
  }),
  deleteTenureSuccess: (data: any) => ({
    type: actionTypes.DELETE_TENURE_SUCCESS,
    payload: data,
  }),
  deleteTenureFinish: () => ({
    type: actionTypes.DELETE_TENURE_FINISH,
  }),

  //Enable Tenure
  enableTenure: (data: any) => ({
    type: actionTypes.ENABLE_TENURE_REQUEST,
    payload: {data},
  }),

  enableTenureSuccess: (task: any) => ({
    type: actionTypes.ENABLE_TENURE_SUCCESS,
    payload: task,
  }),
  enableTenureFinish: () => ({
    type: actionTypes.ENABLE_TENURE_FINISH,
  }),

  //Disable Tenure
  disableTenure: (data: any) => ({
    type: actionTypes.DISABLE_TENURE_REQUEST,
    payload: {data},
  }),

  disableTenureSuccess: (task: any) => ({
    type: actionTypes.DISABLE_TENURE_SUCCESS,
    payload: task,
  }),
  disableTenureFinish: () => ({
    type: actionTypes.DISABLE_TENURE_FINISH,
  }),

  //Enable Tenure
  singleEnableTenure: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_TENURE_REQUEST,
    payload: {data},
  }),

  singleEnableTenureSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_TENURE_SUCCESS,
    payload: task,
  }),
  singleEnableTenureFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_TENURE_FINISH,
  }),

  //Disable Tenure
  singleDisableTenure: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_TENURE_REQUEST,
    payload: {data},
  }),

  singleDisableTenureSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_TENURE_SUCCESS,
    payload: task,
  }),
  singleDisableTenureFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_TENURE_FINISH,
  }),

  // sort
  sortTenure: (data: SortTenureModel) => ({
    type: actionTypes.SORT_TENURE_START,
    payload: data,
  }),
  sortTenureSuccess: (data: Array<TenureModel>) => ({
    type: actionTypes.SORT_TENURE_SUCCESS,
    payload: data,
  }),
  sortTenureFinish: () => ({
    type: actionTypes.SORT_TENURE_FINISH,
  }),
  sortTenureReset: () => ({
    type: actionTypes.SORT_TENURE_RESET,
  }),
}
