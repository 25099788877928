import {useEffect, useState} from 'react'
import ImagePreview from '../ImagePreview'
import SingleColumn from '../SingleColumn'

type Props = {
  data?: any
  isMinor?: boolean
  documents?: any
  isNrn?: boolean
  dobBS?: string
  dobAD?: string
  salutation?: any
}

const BasicDetails = ({data, isMinor, documents, isNrn, dobBS, dobAD, salutation}: Props) => {
  const [ppPhoto, setPpPhoto] = useState('')
  const [signature, setSignature] = useState('')
  const [nrnPhoto, setNrnPhoto] = useState('')

  useEffect(() => {
    const pp = documents?.find(
      (element: any) =>
        element?.fileType?.code === 'personal' && element?.fileType?.title === 'Passport Photo'
    )
    setPpPhoto(pp?.file)

    const nrn = documents?.find(
      (element: any) => element?.fileType?.code === 'personal' && element?.fileType?.title === 'NRN'
    )
    setNrnPhoto(nrn?.file)

    const sign = documents?.find(
      (element: any) =>
        element?.fileType?.code === 'identity' && element?.fileType?.title === 'Signature'
    )
    setSignature(sign?.file)
  }, [documents])
  return (
    <>
      <div className='mb-1 text-primary fs-5 fw-bold'>Basic Details</div>
      <div className='row'>
        <SingleColumn
          label='Full Name'
          value={salutation ? salutation?.title + ' ' + data?.fullName : data?.fullName}
        />
        <SingleColumn label='Date of Birth (B.S)' value={dobBS} />
        <SingleColumn label='Date of Birth (A.D)' value={dobAD} />
        <SingleColumn label='Account Category' value={data?.accountCategory?.title} />
        {/* <SingleColumn label="Account Type" value={data?.accountType?.title} /> */}
        <SingleColumn label='Account Purpose' value={data?.accountPurpose?.title} />
        <SingleColumn label='Account Currency' value={data?.accountCurrency?.title} />
        <SingleColumn label='Gender' value={data?.gender?.title} />
        <SingleColumn label='Nationality' value={data?.nationality?.title} />
        <SingleColumn label='Marital Status' value={data?.maritalStatus?.title} />
        <SingleColumn label='Preferred Branch' value={data?.branch?.title} />
        <SingleColumn label='Other Account Purpose' value={data?.accountPurposeOther} />
        <SingleColumn label='Pan Number' value={data?.panNumber} />
        {/* {isMinor && <SingleColumn label="Date of attaining maturity" value={data?.majorityDate} />} */}
        <div className='col-12'>
          <div className='row'>
            <div className='mb-3 col-12 col-md-6 col-lg-4'>
              <ImagePreview imageSrc={ppPhoto} label='PP Photo:' />
            </div>
            {isNrn && (
              <div className='mb-3 col-12 col-md-6 col-lg-4'>
                <ImagePreview imageSrc={nrnPhoto} label='NRN Id Photo' />
              </div>
            )}
            {/* <div className='mb-3 col-12 col-md-6 col-lg-4'>
                            <ImagePreview imageSrc={signature} label="Signature:" />
                        </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default BasicDetails
