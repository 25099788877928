import ImagePreview from "./ImagePreview"
import SingleColumn from "./SingleColumn"

type Props = {
    data?: any,
    fileURL?: any,
}

const Other = ({ data, fileURL }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Other Detail</div>
            <div className="row">
                <SingleColumn label="Name of ID issuing office" value={data?.issuedOffice} />
                <SingleColumn label="Address of ID issuing office" value={data?.issuedOfficeAddress} />
                <SingleColumn label="ID Document No." value={data?.idNumber} />
                <SingleColumn label="Type of ID Document" value={data?.otherIdName} />
                <SingleColumn label="Issued Date (B.S)" value={data?.issuedDateBs} />
                <SingleColumn label="Issued Date(A.D)" value={data?.issuedDateAd} />
                <div className="col-12">
                    <div className="row">
                        <div className='mb-3 col-12 col-md-6 col-lg-4'>
                            <ImagePreview imageSrc={fileURL} label="Document Photo:" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Other