import SingleColumn from "../SingleColumn"

type Props = {
    data?: any
}

const transform = (value: string) => {
    if (value === "0") {
        return "No"
    } else {
        return "Yes"
    }
}

const SelfDeclarations = ({ data }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Self Declarations</div>
            <div className="row">
                <SingleColumn label="Convicted/Non Convicted for any crime in Past" value={transform(data?.committedCrime)} />
                <SingleColumn label="Politician or relative of Politician" value={transform(data?.politician)} />
                <SingleColumn label="Hold residential permit of foreign country" value={transform(data?.isNrn)} />

            </div>
        </>
    )
}

export default SelfDeclarations