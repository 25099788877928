import SingleColumn from "../SingleColumn"

type Props = {
    data?: any
}

const transform = (value: string) => {
    if (value === "0") {
        return "No"
    } else {
        return "Yes"
    }
}

const BankingProducts = ({ data }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Interested Banking Products</div>
            <div className="row">
                <SingleColumn label="Debit Card" value={transform(data?.debitCard)} />
                <SingleColumn label="Cheque Book" value={transform(data?.chequeBook)} />
                <SingleColumn label="Locker" value={transform(data?.locker)} />
                <SingleColumn label="Demat" value={transform(data?.demat)} />
                <SingleColumn label="Others" value={data?.otherService} />
            </div>
        </>
    )
}

export default BankingProducts