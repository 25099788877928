import React from 'react'
import SingleColumn from '../SingleColumn'

type Props = {
  data: any
}

const UserLocation = ({data}: Props) => {
  return (
    <>
      <div className='mb-1 text-primary fs-5 fw-bold'>Applicant Location</div>
      <div className='row'>
        <SingleColumn label='Longitude' value={data?.longitude} />
        <SingleColumn label='Latitude' value={data?.latitude} />
      </div>
    </>
  )
}

export default UserLocation
