import { Action } from "redux";
import { actionTypes } from "./constants";

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export interface PreVerifiedState {
    data: {
        // customerDetail: { [key: string]: number | string }[]
        preliminaryVerifiedDetail: { [key: string]: number | string }[]
        meta: { [key: string]: number }
    }
    sortVerifiedData?: PreVerifiedState[]
    customerDetailList?: { customerDetail: any[] | any }
    loading: false
    success: false
    editSuccess: false
    activateSuccess: false
    deactivateSuccess: false
    deleteSuccess: false
    toggleLoading: false
    singleActivateSuccess: false
    singleDeactivateSuccess: false,
    resendLinkSuccess: false,
}

const initialPreVerifiedState: PreVerifiedState = {
    data: {
        // customerDetail: [],
        preliminaryVerifiedDetail: [],
        meta: {},
    },
    sortVerifiedData: [],
    customerDetailList: { customerDetail: [] },
    loading: false,
    success: false,
    editSuccess: false,
    activateSuccess: false,
    deactivateSuccess: false,
    deleteSuccess: false,
    toggleLoading: false,
    singleActivateSuccess: false,
    singleDeactivateSuccess: false,
    resendLinkSuccess: false,
}

export const reducer = (
    state: PreVerifiedState = initialPreVerifiedState,
    action: ActionWithPayload<PreVerifiedState>
) => {
    switch (action.type) {
        case actionTypes.GET_PRELIMINARY_VERIFIED_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.GET_PRELIMINARY_VERIFIED_SUCCESS: {
            return {
                ...state,
                data: action.payload?.data,
                loading: false
            }
        }

        case actionTypes.GET_PRELIMINARY_VERIFIED_FINISH: {
            const error = action.payload
            return {
                ...state,
                error,
                loading: false
            }
        }

        case actionTypes.EXPORT_VERIFIED_FILE_START: {
            return { ...state }
        }

        case actionTypes.EXPORT_VERIFIED_FILE_SUCCESS: {
            return { ...state, success: true }
        }

        case actionTypes.EXPORT_VERIFIED_FILE_FINISH: {
            const error = action.payload
            return { ...state, error, success: false }
        }

        case actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_START: {
            return { ...state }
        }

        case actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_SUCCESS: {
            return {
                ...state,
                resendLinkSuccess: true
            }
        }

        case actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_FINISH: {
            return {
                ...state,
                resendLinkSuccess: false
            }
        }

        default:
            return state
    }
}
