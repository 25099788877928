import ImagePreview from "./ImagePreview"
import SingleColumn from "./SingleColumn"

type Props = {
    data?: any,
    fileURL?: any
}

const Passport = ({ data, fileURL }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Passport Detail</div>
            <div className="row">
                <SingleColumn label="Passport No." value={data?.idNumber} />
                <SingleColumn label="Issued Country" value={data?.country?.title} />
                <SingleColumn label="Issued Date (B.S)" value={data?.issuedDateBs} />
                <SingleColumn label="Issued Date(A.D)" value={data?.issuedDateAd} />
                <SingleColumn label="Expiry Date (B.S)" value={data?.expiryDateBs} />
                <SingleColumn label="Expiry Date(A.D)" value={data?.expiryDateAd} />
                <div className="col-12">
                    <div className="row">
                        <div className='mb-3 col-12 col-md-6 col-lg-4'>
                            <ImagePreview imageSrc={fileURL} label="Original Passport Photo:" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Passport