import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getFDRequestSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getFDRequestList, params)
    const data: any = response?.data?.data
    yield put(actions.getAllFDRequestListSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllFDRequestListError(error))
  }
}

function* getLogSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getLogList, params)
    const data: any = response?.data?.data

    yield put(actions.getAllLogSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllLogError(error))
  }
}

function* updateFDStatusSaga(action: ActionModel) {
  try {
    const body: any = action?.payload

    const response: ResponseModel = yield call(service.updateStatus, body)
    yield put({type: actionTypes.UPDATE_FD_STATUS_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_FD_STATUS_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_FD_STATUS_FINISH})
  }
}

function* exportFDRequestFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(service.exportFDRequestList, fileName, params)
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_FD_REQUEST_LIST_START, getFDRequestSaga)
  yield takeLatest(actionTypes.EXPORT_FD_REQUEST_FILE_START, exportFDRequestFileSaga)
  yield takeLatest(actionTypes.GET_FD_LOG_START, getLogSaga)
  yield takeLatest(actionTypes.UPDATE_FD_STATUS_REQUEST, updateFDStatusSaga)
}
