import SingleColumn from '../../AccountOpening/SingleColumn'

const TenureInformation = ({data}: {data: any}) => {
  return (
    <div>
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Tenure Information</h3>
        <SingleColumn label='Title' value={data?.title} />
      </div>
    </div>
  )
}

export default TenureInformation
