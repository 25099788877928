import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_FD_REQUEST_UNVERIFIED = `${API_URL}/fd-customer-data`
export const GET_FD_REQUEST_UNVERIFIED_EXPORT = `${API_URL}/fd-customer-data/dataExport`
export const GET_FD_REQUEST_UNVERIFIED_DETAIL = `${API_URL}//api/fd-customer-data`
export const RESEND_OTP = `${API_URL}/resend-otp`

export const service = {
  getFDRequestUnverified: (params: ParamsModel) => {
    return axios.get(GET_FD_REQUEST_UNVERIFIED, {params})
  },

  exportFDRequestUnverifiedFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${GET_FD_REQUEST_UNVERIFIED_EXPORT}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  getFDRequestUnverifiedDetail: (customerId: string) => {
    return axios.get(`${GET_FD_REQUEST_UNVERIFIED_DETAIL}/${customerId}`)
  },

  resendOTP: (customerId: string) => {
    return axios.get(`${RESEND_OTP}`, {
      params: {
        customerId,
      },
    })
  },
}
