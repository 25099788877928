// import { ParamsModel } from "../Model/index";
import { ParamsModel } from "src/app/modules/common/Model";
import { actionTypes } from "./constants";
import { PreVerifiedState } from "./reducer";

export const actions = {
    getAllPreliminaryUnverified: (params: ParamsModel = { page: 1, limit: 10 }) => {
        return {
            type: actionTypes.GET_PRELIMINARY_UNVERIFIED_START,
            payload: { params }
        }
    },

    getAllPreliminaryUnverifiedSuccess: (data: PreVerifiedState) => ({
        type: actionTypes.GET_PRELIMINARY_UNVERIFIED_SUCCESS,
        payload: data
    }),

    getAllPreliminaryUnverifiedError: (data: PreVerifiedState) => ({
        type: actionTypes.GET_PRELIMINARY_UNVERIFIED_FINISH,
        payload: data
    }),

    //export file
    exportFile: (fileName: string, params: ParamsModel) => {
        return {
            type: actionTypes.EXPORT_UNVERIFIED_FILE_START,
            payload: { fileName, params }
        }
    },

    exportFileSuccess: (data: any) => ({
        type: actionTypes.EXPORT_UNVERIFIED_FILE_SUCCESS,
        payload: data,
    }),

    exportFileError: () => ({
        type: actionTypes.EXPORT_UNVERIFIED_FILE_FINISH,
    }),

    //resend otp code
    resendOtp: (customerId?: string) => {
        return {
            type: actionTypes.RESEND_PRELIMINARY_OTP_CODE_START,
            payload: {
                customerId
            }
        }
    },

    resendOtpSuccess: () => ({
        type: actionTypes.RESEND_PRELIMINARY_OTP_CODE_SUCCESS
    }),

    resendOtpFinish: () => ({
        type: actionTypes.RESEND_PRELIMINARY_OTP_CODE_FINISH
    })
}
