import GuardianIdentificationData from './guardianIdentificationData'
import IdentificationData from './identificationData'
import MinorIdentification from './minorIdentificationData'
import Signature from './signatureData'

type Props = {
  identificationInformation?: any
  isMinor?: boolean
  accountType?: string
  guardianIdentificationInformation?: any
  documents?: any
}

const IdentityInformation = ({
  identificationInformation,
  isMinor,
  accountType,
  guardianIdentificationInformation,
  documents,
}: Props) => {
  return (
    <div>
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Identification Information</h3>
      </div>
      {accountType === 'INDIVIDUAL' && (
        <IdentificationData
          identificationInformation={identificationInformation}
          documents={documents}
        />
      )}
      {accountType === 'MINOR' && (
        <>
          <MinorIdentification
            identificationInformation={identificationInformation}
            documents={documents}
          />
          <GuardianIdentificationData
            guardianIdentificationInformation={guardianIdentificationInformation}
            documents={documents}
          />
        </>
      )}
      <Signature documents={documents} />
    </div>
  )
}

export default IdentityInformation
