import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {IconButton, Pagination, Tooltip, Whisper} from 'rsuite'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import {ItemType, StateParamsModel} from 'src/app/modules/common/Model'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as productManagerRedux from 'src/app/modules/products/productManager/redux'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import AddProductManager from './AddProductManager'
const Cell = Table.Cell

const ProductManager = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 5,
    limit: 10,
  })
  const productManagerData: productManagerRedux.IProductManagerState = useSelector((state: any) => {
    return state?.productManager
  })

  const {
    loading,
    singleDisableSuccess,
    singleEnableSuccess,
    deleteSuccess,
    sortProductManagerData,
    success,
    enableSuccess,
    disableSuccess,
  } = productManagerData

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [openSortModal, setOpenSortModal] = useState(false)

  const data =
    productManagerData?.data?.productManager?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      customerId: item?.customerId,
      firstName: item?.firstName,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleDelete = () => {
    let productManagerId = checkedValues?.map((value) => ({id: value}))
    dispatch(productManagerRedux?.actions?.deleteProductManager(productManagerId))
    setAlertOpen(false)
  }

  const handleGetAllData = () => {
    setParams({...params, limit: productManagerData?.data?.meta?.total ?? 0})
    dispatch(productManagerRedux.actions.getAllProductManager(params))
  }

  const handleToggleAction = (data: {[key: string]: string}) => {
    data?.status
      ? dispatch(productManagerRedux.actions.singleDisableProductManager({id: data.id}))
      : dispatch(productManagerRedux.actions.singleEnableProductManager({id: data.id}))
  }

  const enableProductManagerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(productManagerRedux.actions.enableProductManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableProductManagerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(productManagerRedux.actions.disableProductManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  useEffect(() => {
    dispatch(productManagerRedux.actions.getAllProductManager(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Products Manager enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Products Manager disabled successfully')
    }
    if (deleteSuccess) {
      toast.success('Products Manager deleted successfully')
      dispatch(productManagerRedux?.actions.getAllProductManager(params))
    }
    if (enableSuccess) {
      toast.success('Products Manager enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Products Manager disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess, deleteSuccess, enableSuccess, disableSuccess])

  useEffect(() => {
    if (!isEmpty(sortProductManagerData) && success) {
      toast.success('Product Manager sorted successfully')
      dispatch(productManagerRedux.actions.sortProductManagerReset())
      setParams({...params, limit: 10})
      dispatch(productManagerRedux.actions.getAllProductManager(params))
      setOpenSortModal(false)
    }
  }, [sortProductManagerData, success])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
          <IconButton
            appearance='subtle'
            icon={<Edit2 />}
            onClick={() => {
              setEditCheckedData(rowData)
              setActionType('Edit')
              setOpen(true)
            }}
          />
        </Whisper>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              handleAlertOpen()
              setCheckedValues([rowData.id])
            }}
            icon={<TrashIcon />}
          />
        </Whisper>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={false}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
          />
        </Whisper>
      </Cell>
    )
  }

  //table columns
  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title',
      dataKey: 'title',
      cell: <Cell dataKey='title' />,
      sortable: true,
      width: 230,
    },

    {
      label: 'Slug',
      dataKey: 'slug',
      cell: <Cell dataKey='slug' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Interest Rate',
      dataKey: 'interestRate',
      cell: <Cell dataKey='interestRate' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Payment Frequency',
      dataKey: 'paymentFrequency',
      width: 230,
      cell: <Cell dataKey='paymentFrequency' />,
      sortable: true,
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData: any) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
    },

    {
      label: 'Action',
      width: 100,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleRefresh = () => {
    dispatch(productManagerRedux.actions.getAllProductManager(params))
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      productManagerId: data?.map((item) => ({id: item?.keyId})),
    }
    dispatch(productManagerRedux.actions.sortProductManager(body))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Product Manager'
          params={params}
          setParams={setParams}
          addShow={true}
          deleteShow={true}
          filterData={false}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          importShow={false}
          exportShow={false}
          toggleMultipleShow={true}
          data={data}
          sortButtonName='Sort'
          sortShow={true}
          handleGetAllData={handleGetAllData}
          loading={loading}
          handleAddModal={handleAddModal}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          handleSubmitSort={handleSubmitSort}
          enableMultiple={enableProductManagerData}
          disableMultiple={disableProductManagerData}
        />
        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={productManagerData?.loading}
            onChecked={handleChecked}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={productManagerData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open && (
          <AddProductManager
            open={open}
            actionType={actionType}
            handleClose={() => {
              setOpen(false)
            }}
            editSelectedData={editSelectedData}
          />
        )}

        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => {
                setAlertOpen(false)
              }}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}

export default ProductManager
