export const actionTypes = {
  // get Banners
  GET_BANNER_START: 'GET_BANNER_START',
  GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
  GET_BANNER_FINISH: 'GET_BANNER_FINISH',

  // get Banners
  GET_ALL_BANNER_START: 'GET_ALL_BANNER_START',
  GET_ALL_BANNER_SUCCESS: 'GET_ALL_BANNER_SUCCESS',
  GET_ALL_BANNER_FINISH: 'GET_ALL_BANNER_FINISH',

  // get Color OPtion
  GET_COLOR_OPTION_START: 'GET_COLOR_OPTION_START',
  GET_COLOR_OPTION_SUCCESS: 'GET_COLOR_OPTION_SUCCESS',
  GET_COLOR_OPTION_FINISH: 'GET_COLOR_OPTION_FINISH',

  //Add Banner
  ADD_BANNER_REQUEST: 'ADD_BANNER_REQUEST',
  ADD_BANNER_SUCCESS: 'ADD_BANNER_SUCCESS',
  ADD_BANNER_FINISH: 'ADD_BANNER_FINISH',
  ADD_BANNER_RESET: 'ADD_BANNER_RESET',

  // update Banner
  UPDATE_BANNER_START: 'UPDATE_BANNER_START',
  UPDATE_BANNER_SUCCESS: 'UPDATE_BANNER_SUCCESS',
  UPDATE_BANNER_FINISH: 'UPDATE_BANNER_FINISH',
  UPDATE_BANNER_RESET: 'UPDATE_BANNER_RESET',

  //Bulk Activate Banner
  ACTIVATE_BANNER_REQUEST: 'ACTIVATE_BANNER_REQUEST',
  ACTIVATE_BANNER_SUCCESS: 'ACTIVATE_BANNER_SUCCESS',
  ACTIVATE_BANNER_FINISH: 'ACTIVATE_BANNER_FINISH',

  //Bulk Deactivate Banner
  DEACTIVATE_BANNER_REQUEST: 'DEACTIVATE_BANNER_REQUEST',
  DEACTIVATE_BANNER_SUCCESS: 'DEACTIVATE_BANNER_SUCCESS',
  DEACTIVATE_BANNER_FINISH: 'DEACTIVATE_BANNER_FINISH',

  //Single Activate Banner
  SINGLE_ACTIVATE_BANNER_REQUEST: 'SINGLE_ACTIVATE_BANNER_REQUEST',
  SINGLE_ACTIVATE_BANNER_SUCCESS: 'SINGLE_ACTIVATE_BANNER_SUCCESS',
  SINGLE_ACTIVATE_BANNER_FINISH: 'SINGLE_ACTIVATE_BANNER_FINISH',

  //Single Deactivate Banner
  SINGLE_DEACTIVATE_BANNER_REQUEST: 'SINGLE_DEACTIVATE_BANNER_REQUEST',
  SINGLE_DEACTIVATE_BANNER_SUCCESS: 'SINGLE_DEACTIVATE_BANNER_SUCCESS',
  SINGLE_DEACTIVATE_BANNER_FINISH: 'SINGLE_DEACTIVATE_BANNER_FINISH',

  // delete Banner
  DELETE_BANNER_START: 'DELETE_BANNER_START',
  DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
  DELETE_BANNER_FINISH: 'DELETE_BANNER_FINISH',

  // sort
  SORT_BANNER_START: 'SORT_BANNER_START',
  SORT_BANNER_SUCCESS: 'SORT_BANNER_SUCCESS',
  SORT_BANNER_FINISH: 'SORT_BANNER_FINISH',
  SORT_BANNER_RESET: 'SORT_BANNER_RESET',
}
