import AccountOpeningDetail from 'src/app/modules/common/components/AccountOpening'

const PendingAccountDetail = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <AccountOpeningDetail displayChangeStatus={true} displayAccountNumberField={true} />
    </div>
  )
}

export default PendingAccountDetail
