import {CheckboxGroup} from 'rsuite'
import AccountOpeningDetail from 'src/app/modules/common/components/AccountOpening'

const AccountOpenDetail = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <AccountOpeningDetail displayChangeStatus={false} />
    </div>
  )
}

export default AccountOpenDetail
