import SingleColumn from '../SingleColumn'

type Props = {
  data?: any
  applyingFromInside?: boolean
}

const AddressDetail = ({data, applyingFromInside}: Props) => {
  return (
    <>
      <div className='mb-1 text-primary fs-5 fw-bold'>{data?.addressType?.title}</div>
      <div className='row'>
        {/* <div className='mb-3 col-12 col-md-6 col-lg-4'> */}
        <SingleColumn label='Country' value={data?.country?.title} />
        {!applyingFromInside && (
          <SingleColumn label='Full Address' value={data?.outsideFullAddress} />
        )}
        {(applyingFromInside || data?.country?.title === 'NEPAL') && (
          <>
            <SingleColumn label='Province' value={data?.province?.title} />
            <SingleColumn label='District' value={data?.district?.title} />
            <SingleColumn label='Local Body' value={data?.localBody?.title} />
            <SingleColumn label='Ward No.' value={data?.wardNo} />
            <SingleColumn label='Tole' value={data?.tole} />
          </>
        )}
        <SingleColumn label='House No.' value={data?.houseNo} />
        <SingleColumn label='Post Box' value={data?.postBox} />
        <SingleColumn label='Phone' value={data?.phoneNumber} />
        <SingleColumn label='Mobile' value={data?.mobileNumber} />
        <SingleColumn label='Email' value={data?.email} />
        {/* </div> */}
      </div>
    </>
  )
}

export default AddressDetail
