import SingleColumn from "../SingleColumn"

type Props = {
    educationalQualification?: string
}

const EducationQualification = ({ educationalQualification }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Educational Qualification</div>
            <div className="row">
                {/* <div className='mb-3 col-12 col-md-6 col-lg-4'> */}
                <SingleColumn label="Educational Qualification" value={educationalQualification} />
                {/* </div> */}
            </div>
        </>
    )
}

export default EducationQualification