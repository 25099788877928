export const actionTypes = {
  //get preliminary verified
  GET_CHECKER_LIST_START: 'GET_CHECKER_LIST_START',
  GET_CHECKER_LIST_SUCCESS: 'GET_CHECKER_LIST_SUCCESS',
  GET_CHECKER_LIST_FINISH: 'GET_CHECKER_LIST_FINISH',

  //get Export file
  EXPORT_CHECKER_FILE_START: 'EXPORT_CHECKER_FILE_START',
  EXPORT_CHECKER_FILE_SUCCESS: 'EXPORT_CHECKER_FILE_SUCCESS',
  EXPORT_CHECKER_FILE_FINISH: 'EXPORT_CHECKER_FILE_FINISH',

  //Add Remarks
  ADD_CHECKER_REMARKS_REQUEST: 'ADD_CHECKER_REMARKS_REQUEST',
  ADD_CHECKER_REMARKS_SUCCESS: 'ADD_CHECKER_REMARKS_SUCCESS',
  ADD_CHECKER_REMARKS_FINISH: 'ADD_CHECKER_REMARKS_FINISH',
  ADD_CHECKER_REMARKS_RESET: 'ADD_CHECKER_REMARKS_RESET',
}
