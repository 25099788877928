export const actionTypes = {
  //get preliminary verified
  GET_PENDING_ACCOUNT_LIST_START: 'GET_PENDING_ACCOUNT_LIST_START',
  GET_PENDING_ACCOUNT_LIST_SUCCESS: 'GET_PENDING_ACCOUNT_LIST_SUCCESS',
  GET_PENDING_ACCOUNT_LIST_FINISH: 'GET_PENDING_ACCOUNT_LIST_FINISH',

  //get Export file
  EXPORT_PENDING_FILE_START: 'EXPORT_PENDING_FILE_START',
  EXPORT_PENDING_FILE_SUCCESS: 'EXPORT_PENDING_FILE_SUCCESS',
  EXPORT_PENDING_FILE_FINISH: 'EXPORT_PENDING_FILE_FINISH',
}
