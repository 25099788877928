export const actionTypes = {
  // get
  GET_ALL_TENURE_START: 'GET_ALL_TENURE_START',
  GET_ALL_TENURE_SUCCESS: 'GET_ALL_TENURE_SUCCESS',
  GET_ALL_TENURE_FINISH: 'GET_ALL_TENURE_FINISH',

  // extended hours
  GET_TENURE_START: 'GET_TENURE_START',
  GET_TENURE_SUCCESS: 'GET_TENURE_SUCCESS',
  GET_TENURE_FINISH: 'GET_TENURE_FINISH',

  // add branch
  ADD_TENURE_START: 'ADD_TENURE_START',
  ADD_TENURE_SUCCESS: 'ADD_TENURE_SUCCESS',
  ADD_TENURE_FINISH: 'ADD_TENURE_FINISH',
  RESET_PROGRAM_TAG: 'RESET_PROGRAM_TAG',

  // update PROGRAM_TAG
  UPDATE_TENURE_START: 'UPDATE_TENURE_START',
  UPDATE_TENURE_SUCCESS: 'UPDATE_TENURE_SUCCESS',
  UPDATE_TENURE_FINISH: 'UPDATE_TENURE_FINISH',

  // delete PROGRAM_TAG
  DELETE_TENURE_START: 'DELETE_TENURE_START',
  DELETE_TENURE_SUCCESS: 'DELETE_TENURE_SUCCESS',
  DELETE_TENURE_FINISH: 'DELETE_TENURE_FINISH',

  // Enable PROGRAM_TAG
  ENABLE_TENURE_REQUEST: 'ENABLE_TENURE_REQUEST',
  ENABLE_TENURE_SUCCESS: 'ENABLE_TENURE_SUCCESS',
  ENABLE_TENURE_FINISH: 'ENABLE_TENURE_FINISH',

  // Disable PROGRAM_TAG
  DISABLE_TENURE_REQUEST: 'DISABLE_TENURE_REQUEST',
  DISABLE_TENURE_SUCCESS: 'DISABLE_TENURE_SUCCESS',
  DISABLE_TENURE_FINISH: 'DISABLE_TENURE_FINISH',

  // Enable PROGRAM_TAG
  SINGLE_ENABLE_TENURE_REQUEST: 'SINGLE_ENABLE_TENURE_REQUEST',
  SINGLE_ENABLE_TENURE_SUCCESS: 'SINGLE_ENABLE_TENURE_SUCCESS',
  SINGLE_ENABLE_TENURE_FINISH: 'SINGLE_ENABLE_TENURE_FINISH',

  // Disable PROGRAM_TAG
  SINGLE_DISABLE_TENURE_REQUEST: 'SINGLE_DISABLE_TENURE_REQUEST',
  SINGLE_DISABLE_TENURE_SUCCESS: 'SINGLE_DISABLE_TENURE_SUCCESS',
  SINGLE_DISABLE_TENURE_FINISH: 'SINGLE_DISABLE_TENURE_FINISH',

  // sort
  SORT_TENURE_START: 'SORT_TENURE_START',
  SORT_TENURE_SUCCESS: 'SORT_TENURE_SUCCESS',
  SORT_TENURE_FINISH: 'SORT_TENURE_FINISH',
  SORT_TENURE_RESET: 'SORT_TENURE_RESET',
}
