import SingleColumn from "../SingleColumn";

type Props = {
    businessData: any[];
};

const BusinessProfessionalDetails = ({ businessData }: Props) => {
    return (
        <>
            {businessData?.length > 0 && <div className="mb-1 text-primary fs-5 fw-bold">Professional Details</div>}
            {businessData?.map((element: any) => (
                <div className="row" key={element?.businessName}>
                    <SingleColumn label="Name of employer/business" value={element?.businessName} />
                    <SingleColumn label="Designation" value={element?.position} />
                    <SingleColumn label="Phone Number" value={element?.telephone} />
                </div>
            ))}
        </>
    );
};

export default BusinessProfessionalDetails;