export const actionTypes = {
    GET_ALL_PRODUCT_MANAGER_START: 'GET_ALL_PRODUCT_MANAGER_START',
    GET_ALL_PRODUCT_MANAGER_SUCCESS: 'GET_ALL_PRODUCT_MANAGER_SUCCESS',
    GET_ALL_PRODUCT_MANAGER_FINISH: 'GET_ALL_PRODUCT_MANAGER_FINISH',

    ADD_PRODUCT_MANAGER_START: 'ADD_PRODUCT_MANAGER_START',
    ADD_PRODUCT_MANAGER_SUCCESS: 'ADD_PRODUCT_MANAGER_SUCCESS',
    ADD_PRODUCT_MANAGER_FINISH: 'ADD_PRODUCT_MANAGER_FINISH',
    RESET_PRODUCT_MANAGER: 'RESET_PRODUCT_MANAGER',

    UPDATE_PRODUCT_MANAGER_START: 'UPDATE_PRODUCT_MANAGER_START',
    UPDATE_PRODUCT_MANAGER_SUCCESS: 'UPDATE_PRODUCT_MANAGER_SUCCESS',
    UPDATE_PRODUCT_MANAGER_FINISH: 'UPDATE_PRODUCT_MANAGER_FINISH',

    DELETE_PRODUCT_MANAGER_START: 'DELETE_PRODUCT_MANAGER_START',
    DELETE_PRODUCT_MANAGER_SUCCESS: 'DELETE_PRODUCT_MANAGER_SUCCESS',
    DELETE_PRODUCT_MANAGER_FINISH: 'DELETE_PRODUCT_MANAGER_FINISH',

    SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST: 'SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST',
    SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS: 'SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS',
    SINGLE_DISABLE_PRODUCT_MANAGER_FINISH: 'SINGLE_DISABLE_PRODUCT_MANAGER_FINISH',

    SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST: 'SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST',
    SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS: 'SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS',
    SINGLE_ENABLE_PRODUCT_MANAGER_FINISH: 'SINGLE_ENABLE_PRODUCT_MANAGER_FINISH',

    ENABLE_PRODUCT_MANAGER_REQUEST: 'ENABLE_PRODUCT_MANAGER_REQUEST',
    ENABLE_PRODUCT_MANAGER_SUCCESS: 'ENABLE_PRODUCT_MANAGER_SUCCESS',
    ENABLE_PRODUCT_MANAGER_FINISH: 'ENABLE_PRODUCT_MANAGER_FINISH',

    // Disable PRODUCT_TAG
    DISABLE_PRODUCT_MANAGER_REQUEST: 'DISABLE_PRODUCT_MANAGER_REQUEST',
    DISABLE_PRODUCT_MANAGER_SUCCESS: 'DISABLE_PRODUCT_MANAGER_SUCCESS',
    DISABLE_PRODUCT_MANAGER_FINISH: 'DISABLE_PRODUCT_MANAGER_FINISH',

    // sort
    SORT_PRODUCT_MANAGER_START: 'SORT_PRODUCT_MANAGER_START',
    SORT_PRODUCT_MANAGER_SUCCESS: 'SORT_PRODUCT_MANAGER_SUCCESS',
    SORT_PRODUCT_MANAGER_FINISH: 'SORT_PRODUCT_MANAGER_FINISH',
    SORT_PRODUCT_MANAGER_RESET: 'SORT_PRODUCT_MANAGER_RESET',
}