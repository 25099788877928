import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getOnlineOnPrimListSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAccountOpenList, params)
    const data: any = response?.data?.data

    yield put(actions.getAllAccountOpenListSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllAccountOpenError(error))
  }
}

function* exportMakerListFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(
      service.exportPreliminaryVerifiedFile,
      fileName,
      params
    )
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

function* getFileTypesSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    const response: ResponseModel = yield call(service.getFileTypes, params)
    const data: any = response?.data?.data

    yield put(actions.getFileTypesSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

function* storeDocumentUploadSaga(action: ActionModel) {
  try {
    yield delay(500)
    const payload: any = action.payload
    const response: ResponseModel = yield call(service.uploadDocument, payload)
    yield put(actions.uploadOnprimDocumentSuccess(response.data))
    yield put(actions.uploadOnprimDocumentError)
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ONLINEONPRIM_LIST_START, getOnlineOnPrimListSaga)
  yield takeLatest(actionTypes.EXPORT_ONLINEONPRIM_FILE_START, exportMakerListFileSaga)
  yield takeLatest(actionTypes.GET_FILE_TYPES_START, getFileTypesSaga)
  yield takeLatest(actionTypes.STORE_ONPRIM_DOCUMENT_UPLOAD_START, storeDocumentUploadSaga)
}
