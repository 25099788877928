import StudentDetails from './StudentDetails'
import AnticipatedVolume from './anticipatedVolume'
import BusinessProfessionalDetails from './bussinessProfessionalDetails'
import EducationQualification from './educationQualification'
import EmployeeInformation from './employeeInfomation'
import Occupation from './occupation'
import OtherBankDetail from './otherBankDetail'

type Props = {
  data?: any
  documents?: Array<any>
}

const ProfessionalInformation = ({data, documents}: Props) => {
  return (
    <div>
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Professional Information</h3>
      </div>
      <EducationQualification
        educationalQualification={data?.professionalDetail?.education?.title}
      />
      <EmployeeInformation
        employeeNumber={data?.professionalDetail?.employeeNumber}
        documents={documents}
      />
      <Occupation occupationDetail={data?.professionalDetail} />
      <BusinessProfessionalDetails
        businessData={
          data?.professionalInformationBusinessCollection?.professionalInformationBusinessData
        }
      />
      <StudentDetails
        studentDetails={
          data?.professionalInformationPersonalCollection?.professionalInformationPersonalData
        }
      />
      <AnticipatedVolume anticipatedVolume={data?.professionalDetail} />
      <OtherBankDetail
        maintainedOtherBankAccount={
          data?.professionalDetail?.maintainedOtherBankAccount === '1' ? true : false
        }
        otherBankDetails={
          data?.professionalInformationOtherBankCollection?.professionalInformationOtherBankData
        }
      />
    </div>
  )
}

export default ProfessionalInformation
