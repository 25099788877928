import {Form, Formik} from 'formik'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Divider, Table} from 'rsuite'
import * as fdRequestListRedux from 'src/app/modules/fdRequest/fdRequestList/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as yup from 'yup'
import {StateParamsModel} from '../../Model'
import ProductInformation from './ProductInformation'
import SingleColumn from './SingleColumn'
import TenureInformation from './TenureInformation'

type Props = {
  displayChangeStatus?: boolean
  options?: Array<any>
  displayAccountNumberField?: boolean
  displayDealAccountNumberField?: boolean
}

const validationSchemaStatus = yup.object().shape({
  status: yup.string().required('Status is required'),
  accountNumber: yup.string().when('status', {
    is: (status: string) => status === 'Accept',
    then: yup
      .string()
      .required('Account Number is required')
      .matches(/^[a-zA-Z0-9]*$/, 'Account number must be alphanumeric'),
    otherwise: yup.string().nullable(),
  }),
  remarks: yup.string().max(1000, 'Remarks should be less than 1000 character'),
})

const FDAccountDetails = ({
  displayChangeStatus,
  options,
  displayAccountNumberField,
  displayDealAccountNumberField,
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const data: any = location.state

  const Cell = Table.Cell

  const [params, setParams] = useState<StateParamsModel>({
    customerId: data?.id,
    page: 1,
    limit: 10,
  })

  const {
    logData: logList,
    loading,
    formSuccess,
  }: any = useSelector((state: any) => {
    return state?.fdRequest
  })

  useEffect(() => {
    dispatch(fdRequestListRedux.actions.getAllLog(params))
  }, [])

  const excludeLogStatus = logList?.logs?.filter((item: any) => {
    const excludedStatusList = [
      'Other Completed',
      'Identity Completed',
      'Professional Completed',
      'Personal Completed',
      ,
    ]
    return !excludedStatusList.includes(item.previousFDStatus)
  })

  const logDataSource =
    excludeLogStatus?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      currentAccountStatus: item?.currentAccountStatus || '-',
      // currentEkycStatus: item?.currentEkycStatus || '-',
      date: moment(item?.date).format('MMMM Do YYYY, h:mm:ss A') || '-',
      remarks: item?.remarks || '-',
    })) ?? []

  // useEffect(() => {

  // }, [])

  const logColumns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
      sortable: false,
    },

    {
      label: 'Date',
      dataKey: 'date',
      // flexGrow: 1,
      cell: <Cell dataKey='date' />,
      sortable: false,
      width: 490,
    },

    {
      label: 'FD Status',
      dataKey: 'currentFDStatus',
      // flexGrow: 1,
      cell: <Cell dataKey='currentFDStatus' />,
      sortable: false,
      width: 490,
    },

    {
      label: 'Remarks',
      dataKey: 'remarks',
      width: 490,
      cell: <Cell dataKey='remarks' />,
      sortable: false,
    },
  ]

  useEffect(() => {
    if (formSuccess) {
      toast.success('Status Updated Successfully')
      history.goBack()
    }
  }, [formSuccess])

  return (
    <>
      <ProductInformation data={data?.product} />
      <TenureInformation data={data?.tenure} />
      <div>
        <div className='mb-0 mt-4'>
          <h3 className='ekyc-title formHeading mb-3'>Personal Information</h3>
        </div>
        <div className='row'>
          <SingleColumn label='Full Name' value={data.name} />
          <SingleColumn label='Email' value={data.email} />
          <SingleColumn label='Mobile Number' value={data.mobileNumber} />
          <SingleColumn label='Account Number' value={data.accountNumber} />
          <SingleColumn label='Interest Rate' value={data.interestRate} />
          <SingleColumn label='Account Type' value={data.accountType} />
          <SingleColumn label='Deposit Amount' value={data.depositAmount} />
          <SingleColumn label='Available Balance' value={data.availableBalance} />
        </div>
      </div>
      {displayDealAccountNumberField && (
        <div>
          <div className='mb-0 mt-4'>
            <h3 className='ekyc-title formHeading mb-3'>
              Deal Reference Number: {data?.dealAccountNumber}
            </h3>
          </div>
        </div>
      )}
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Log Information</h3>
      </div>
      {logDataSource?.length > 0 && (
        <>
          <div className='datatable'>
            <RSuiteTable
              showCheckbox={false}
              columns={logColumns}
              data={logDataSource}
              // checkedValues={selectedData}
              showLoading={logList?.loading}
            />
            {/* <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='sm'
              layout={['total', '-', 'limit', '|', 'pager', 'skip']}
              total={logList?.data?.meta?.total || 0}
              limitOptions={[10, 20, 50, 100]}
              limit={params?.limit}
              activePage={params?.page}
              onChangePage={(value) => setParams({...params, page: value})}
              onChangeLimit={handleChangeLogLimit}
            /> */}
          </div>
          <Divider />
        </>
      )}

      {displayChangeStatus && (
        <>
          <div className='mb-0 mt-4'>
            <h3 className='ekyc-title formHeading mb-3'>Status & Remarks</h3>
          </div>
          <Formik
            initialValues={{remarks: '', status: '', accountNumber: data?.newAccountNumber}}
            validationSchema={validationSchemaStatus}
            onSubmit={(values, {setSubmitting}) => {
              const formData = {
                requestId: data?.id,
                accountNumber: values.status === 'Accept' ? values.accountNumber : '',
                status: values?.status,
                fdStatus: 'FD Submitted',
                remarks: values?.remarks,
              }
              dispatch(fdRequestListRedux.actions.updateStatus(formData))
            }}
          >
            {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
              return (
                <Form>
                  <div className='card-body border-top'>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <FormSelect
                          type='text'
                          labelClassName='col-md-12'
                          placeholder='Select status'
                          label='Status'
                          name='status'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isClearable={true}
                          options={options}
                          required
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {values.status === 'Accept' && (
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            name='accountNumber'
                            labelClassName='col-md-12'
                            placeholder='Account Number'
                            label='Account Number'
                            onChange={handleChange}
                            value={data?.newAccountNumber ?? ''}
                            errors={errors}
                            required={values.status === 'Accept' ? true : false}
                            disabled
                            touched={touched}
                          />
                        </div>
                      )}

                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          as='textarea'
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          placeholder='Remarks'
                          label='Remarks'
                          name='remarks'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      {/* <div className="fv-plugins-message-container text-danger fw-bolder small text-end mt-1">
                                                    <ErrorMessage
                                                        name="status"
                                                        component="div"
                                                        className="field-error-message"
                                                    />
                                                </div> */}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        disabled={loading || errors?.status ? true : false}
                        className='btn btn-primary btn-sm ms-3'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )
}

export default FDAccountDetails
