import {FieldArray, FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent, useEffect} from 'react'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: {[key: string]: string} | any
  editSelectedData: {[key: string]: string} | any
  setFieldValue: any
}

export function ImageDataSection({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  setFieldValue,
}: Props) {
  useEffect(() => {
    if (values.imageData.length < 1) {
      setFieldValue('imageData', [{image: '', title: ''}])
    }
  }, [values.imageData])

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='row'>
            <FieldArray
              name='imageData'
              render={(arrayHelpers) => (
                <div>
                  <h5 className='rs-modal-title mb-5'>Image Data</h5>
                  {values.imageData && values.imageData.length > 0
                    ? values.imageData.map((image: any, index: any) => (
                        <div className='row mb-4' key={index}>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Title'
                              name={`imageData[${index}].title`}
                              label='Text (EN)'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Attached File'
                              name={`imageData[${index}].image`}
                              setFieldValue={setFieldValue}
                              value={values?.attachedFile}
                              required
                              showImageOnly={true}
                            />
                            {!isEmpty(image?.image) ? (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setFieldValue(`imageData[${index}].image`, '')
                                      }}
                                    >
                                      X
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={`${imageBaseUrl}/${image?.image}`}
                                      alt='image'
                                    />
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </div>

                          {index > 0 && (
                            <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                              <button
                                type='button'
                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    : null}
                  {values.imageData[values.imageData.length - 1]?.image && (
                      <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                        <button
                          className='p-2 ps-5 pe-5 btn btn-primary'
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              image: '',
                            })
                          }
                        >
                          + Add More
                        </button>
                      </div>
                    )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
