import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
// import { UserModel } from "src/app/modules/auth/models/UserModel";
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
// import { RootState } from "src/setup";
import {GrView} from 'react-icons/gr'
import {IconButton, Pagination, Tooltip, Whisper} from 'rsuite'
import Table from 'rsuite/Table'
// import * as preliminaryVerifiedRedux from "src/app/modules/onlineAccount/preliminaryVerified/redux";
import {useHistory} from 'react-router-dom'
import * as rejectedListRedux from 'src/app/modules/onlineAccount/rejectedList/redux'
const Cell = Table.Cell

const RejectedList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;

  const {
    user: {
      data: {id: userId},
    },
  } = useSelector((state: any) => state.auth)

  const [params, setParams] = useState<StateParamsModel>({
    userId,
    status: 'Rejected List',
    page: 1,
    limit: 10,
  })
  const rejectedListData: rejectedListRedux.AccountRejectedState = useSelector((state: any) => {
    return state?.rejectedList
  })

  const {loading} = rejectedListData

  const data =
    rejectedListData?.customerDetailList?.customerDetail?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      customerId: item?.customerId,
      firstName: item?.firstName,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleGetAllData = () => {
    setParams({...params, limit: rejectedListData?.data?.meta?.total})
    dispatch(rejectedListRedux.actions.getAllRejectedList(params))
  }

  useEffect(() => {
    dispatch(rejectedListRedux.actions.getAllRejectedList(params))
  }, [params])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              history.push({
                pathname: `/online-account/rejected-list/${rowData.id}`,
                state: rowData,
              })
            }}
            icon={<GrView size={18} />}
          />
        </Whisper>
      </Cell>
    )
  }

  //table columns
  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Customer Id',
      dataKey: 'customerId',
      cell: <Cell dataKey='customerId' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Branch',
      dataKey: 'branchData',
      cell: <Cell dataKey='personalInformation.branch.title' />,
      width: 230,
    },
    {
      label: 'Name',
      dataKey: 'firstName',
      cell: <Cell dataKey='firstName' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Email',
      dataKey: 'email',
      cell: <Cell dataKey='email' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Is Onprim?',
      dataKey: 'status.on_prim',
      width: 230,
      cell: (
        <Cell>
          {(rowData: any) => (
            <>{rowData?.status?.on_prim ? 'Yes' : rowData?.status?.on_prim === 0 ? 'No' : ''}</>
          )}
        </Cell>
      ),
      sortable: true,
    },

    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      width: 230,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },

    {
      label: 'Action',
      width: 100,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleRefresh = () => {
    dispatch(rejectedListRedux.actions.getAllRejectedList(params))
  }

  const handleExport = () => {
    dispatch(rejectedListRedux.actions.exportFile('Rejected List Data', params))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Rejected List'
          params={params}
          setParams={setParams}
          addShow={false}
          deleteShow={false}
          filterData={true}
          handleRefresh={handleRefresh}
          importShow={false}
          exportShow={true}
          toggleMultipleShow={false}
          data={data}
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
          handleExport={handleExport}
          filterByBranch
        />
        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={rejectedListData?.loading}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={rejectedListData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100, 500, 1000, 10000]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  )
}

export default RejectedList
