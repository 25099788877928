export const actionTypes = {
  //get preliminary verified
  GET_CHECKER_REPAIR_LIST_START: 'GET_CHECKER_REPAIR_LIST_START',
  GET_CHECKER_REPAIR_LIST_SUCCESS: 'GET_CHECKER_REPAIR_LIST_SUCCESS',
  GET_CHECKER_REPAIR_LIST_FINISH: 'GET_CHECKER_REPAIR_LIST_FINISH',

  //get Export file
  EXPORT_REPAIR_FILE_START: 'EXPORT_REPAIR_FILE_START',
  EXPORT_REPAIR_FILE_SUCCESS: 'EXPORT_REPAIR_FILE_SUCCESS',
  EXPORT_REPAIR_FILE_FINISH: 'EXPORT_REPAIR_FILE_FINISH',
}
