import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface MakerRepairListState {
  data: {
    repairListDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortVerifiedData?: MakerRepairListState[]
  customerDetailList?: {
    customerDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
  // isFetchingPdf: false,
}

const initialMakerRepairListState: MakerRepairListState = {
  data: {
    repairListDetail: [],
    meta: {},
  },
  sortVerifiedData: [],
  customerDetailList: {
    customerDetail: [],
    meta: {},
  },
  loading: false,
  success: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
}

export const reducer = (
  state: MakerRepairListState = initialMakerRepairListState,
  action: ActionWithPayload<MakerRepairListState>
) => {
  switch (action.type) {
    case actionTypes.GET_MAKER_REPAIR_LIST_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_MAKER_REPAIR_LIST_SUCCESS: {
      return {
        ...state,
        customerDetailList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_MAKER_REPAIR_LIST_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }

    case actionTypes.EXPORT_REPAIR_FILE_START: {
      return {...state}
    }

    case actionTypes.EXPORT_REPAIR_FILE_SUCCESS: {
      return {...state, success: true}
    }

    case actionTypes.EXPORT_REPAIR_FILE_FINISH: {
      const error = action.payload
      return {...state, error, success: false}
    }

    default:
      return state
  }
}
