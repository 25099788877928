import SingleColumn from "../SingleColumn"

type Props = {
    maintainedOtherBankAccount?: boolean,
    otherBankDetails?: any
}

const OtherBankDetail = ({ maintainedOtherBankAccount, otherBankDetails }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Other Bank Details</div>
            <div className="row">
                <SingleColumn label="Has Other Bank" value={maintainedOtherBankAccount ? 'Yes' : 'No'} />

            </div>
            {maintainedOtherBankAccount && (
                <>
                    {otherBankDetails?.map((element: any, index: number) => (
                        <div className="row" key={index}>
                            <SingleColumn label="Name of Bank" value={element?.bankName} />
                            <SingleColumn label="Branch" value={element?.branchName} />
                            <SingleColumn label="Type of Account" value={element?.accountType?.title} />
                        </div>
                    ))}
                </>

            )}
        </>
    )
}

export default OtherBankDetail