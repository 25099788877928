import AddressDetail from "./addressDetail"

type Props = {
    addressDetails?: any,
    applyingFromInside?: boolean
}

const AddressDetails = ({ addressDetails, applyingFromInside }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Address Details</div>
            {
                addressDetails?.map((element: any, index: number) => (
                    <div className="row" key={index}>
                        <AddressDetail key={index} data={element} applyingFromInside={applyingFromInside} />
                    </div>
                ))
            }
            {/* <div className="mb-1 text-primary fs-5 fw-bold">{data?.addressType}</div>
            <div className="row">
                <div className='mb-3 col-12 col-md-6 col-lg-4'>
                    <SingleColumn label="Country" value={data?.country?.title} />
                    <SingleColumn label="Province" value={data?.province?.title} />
                    <SingleColumn label="District" value={data?.district?.title} />
                    <SingleColumn label="Local Body" value={data?.localBody?.title} />
                    <SingleColumn label="Ward No." value={data?.wardNo} />
                    <SingleColumn label="House No." value={data?.houseNo} />
                    <SingleColumn label="Tole" value={data?.tole} />
                    <SingleColumn label="Outside Full Address" value={data?.outsideFullAddress} />
                </div>
            </div> */}
        </>
    )
}

export default AddressDetails