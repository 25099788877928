import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {TenureModel, SortTenureActionModel, SortTenureModel} from '../Model/TenureModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getTenureSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getTenure, params)
    yield put(actions.getTenureSuccess(response?.data?.data))
    yield put(actions.getTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getTenureFinish())
  }
}

function* getAllTenureSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllTenure)
    yield put(actions.getAllTenureSuccess(response?.data?.data))
    yield put(actions.getAllTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllTenureFinish())
  }
}

function* addTenureSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addTenure, body)
    yield put(actions.addTenureSuccess(response.data?.data))
    yield put(actions.addTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addTenureFinish())
  }
}

function* enableTenureSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableTenure, selectedUsers)
    yield put(actions.enableTenureSuccess(response?.data))
    yield put(actions.enableTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableTenureFinish())
  }
}

function* disableTenureSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableTenure, selectedUsers)
    yield put(actions.disableTenureSuccess(response?.data))
    yield put(actions.disableTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableTenureFinish())
  }
}

function* singleEnableTenureSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableTenure, selectedUsers)
    yield put(actions.singleEnableTenureSuccess(response?.data))
    yield put(actions.singleEnableTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableTenureFinish())
  }
}

function* singleDisableTenureSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableTenure, selectedUsers)
    yield put(actions.singleDisableTenureSuccess(response?.data))
    yield put(actions.singleDisableTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableTenureFinish())
  }
}

function* updateTenureSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.updateTenure, body, action.payload?.id)
    yield put(actions.updateTenureSuccess(response.data?.data))
    yield put(actions.updateTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateTenureFinish())
  }
}

function* deleteTenureSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteTenure, body)
    yield put(actions.deleteTenureSuccess(response.data?.data))
    yield put(actions.deleteTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteTenureFinish())
  }
}

function* sortTenure(action: SortTenureActionModel) {
  try {
    const body: SortTenureModel = action.payload
    const response: ResponseModel = yield call(service.sortTenure, body)

    yield put({
      type: actionTypes.SORT_TENURE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_TENURE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_TENURE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_TENURE_START, getTenureSaga)
  yield takeLatest(actionTypes.GET_ALL_TENURE_START, getAllTenureSaga)
  yield takeLatest(actionTypes.ADD_TENURE_START, addTenureSaga)
  yield takeLatest(actionTypes.UPDATE_TENURE_START, updateTenureSaga)
  yield takeLatest(actionTypes.DELETE_TENURE_START, deleteTenureSaga)
  yield takeLatest(actionTypes.ENABLE_TENURE_REQUEST, enableTenureSaga)
  yield takeLatest(actionTypes.DISABLE_TENURE_REQUEST, disableTenureSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_TENURE_REQUEST, singleEnableTenureSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_TENURE_REQUEST, singleDisableTenureSaga)
  yield takeLatest(actionTypes.SORT_TENURE_START, sortTenure)
}
