import axios from "axios";
import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_PRELIMINARY_VERIFIED = `${API_URL}/preliminary-verified`;
export const GET_PRELIMINARY_VERIFIED_EXPORT = `${API_URL}/preliminary-verified/export`;
export const GET_PRELIMINARY_VERIFIED_DETAIL = `${API_URL}/preliminary-verified`;
export const PRELIMINARY_RESEND_LINK = `${API_URL}/reset-link`;

export const service = {
    getPreliminaryVerified: (params: ParamsModel) => {
        return axios.get(GET_PRELIMINARY_VERIFIED, { params })
    },

    exportPreliminaryVerifiedFile: (fileName: string, params: ParamsModel) => {
        return axios.get(`${GET_PRELIMINARY_VERIFIED_EXPORT}`, { params }).then(response => {
            const blob = new Blob([response?.data])
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.csv`) //or any other extension
            document.body.appendChild(link)
            link.click()
        })
    },

    getPreliminaryVerifiedDetail: (customerId: string) => {
        return axios.get(`${GET_PRELIMINARY_VERIFIED_DETAIL}/${customerId}`)
    },

    resendLink: (customerId: string) => {
        return axios.get(`${PRELIMINARY_RESEND_LINK}`, {
            params: {
                customerId
            }
        })
    }
}