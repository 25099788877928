import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_ACCOUNT_FD_REJECTED_LIST = `${API_URL}/fd-customer-data`
export const GET_ACCOUNT_FD_REJECTED_EXPORT = `${API_URL}/fd-customer-data/dataExport`

export const service = {
  getFdRejectedList: (params: ParamsModel) => {
    return axios.get(GET_ACCOUNT_FD_REJECTED_LIST, {params})
  },

  exportFdRejectedRequestFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${GET_ACCOUNT_FD_REJECTED_EXPORT}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
