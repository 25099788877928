import { call, put, takeLatest, delay } from "redux-saga/effects";
import { ActionModel } from "src/cms/helpers/Models/ActionModel";
import { actions } from "./action";
import { ResponseModel } from "src/cms/helpers/Models/ResponseModel";
import { service } from "./service";
import { actionTypes } from "./constants";
import { globalActionTypes } from "src/app/modules/errors/redux/constants";

function* getPreliminaryVerifiedSaga(action: ActionModel) {
    try {
        const params: any = action.payload?.params
        yield delay(500)
        const response: ResponseModel = yield call(service.getPreliminaryVerified, params)
        const data: any = response?.data;
        yield put(actions.getAllPreliminaryVerifiedSuccess(data))
    } catch (error: any) {
        yield put(actions.getAllPreliminaryVerifiedError(error));
    }
}

function* exportPreliminaryVerifiedFileSaga(action: ActionModel) {
    try {
        const fileName: any = action.payload.fileName;
        const params: any = action.payload.params;
        const response: ResponseModel = yield call(
            service.exportPreliminaryVerifiedFile,
            fileName,
            params
        );
        const responseData: any = response?.data;
        yield put(actions.exportFileSuccess(responseData))
        yield put(actions.exportFileError())
    } catch (error: any) {
        if (error.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message })
        }
        yield put(actions.exportFileError())
    }
}

function* resendPreliminaryVerifiedLink(action: ActionModel) {
    try {
        const customerId = action.payload.customerId;
        yield call(service.resendLink, customerId);
        yield put(actions.resendLinkSuccess());
        yield put(actions.resendLinkFinish());
    } catch (error: any) {
        if (error.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message })
        }
        yield put(actions.resendLinkFinish())
    }
}

export function* saga() {
    yield takeLatest(actionTypes.GET_PRELIMINARY_VERIFIED_START, getPreliminaryVerifiedSaga);
    yield takeLatest(actionTypes.EXPORT_VERIFIED_FILE_START, exportPreliminaryVerifiedFileSaga);
    yield takeLatest(actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_START, resendPreliminaryVerifiedLink);
}