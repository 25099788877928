import clsx from 'clsx'
import {isEmpty} from 'lodash'
import {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {IModuleModel} from 'src/app/modules/siteSettings/components/moduleManager/Model'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'
import {AsideMenuMain} from './AsideMenuMain'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const module: any = useSelector((state: any) => state.auth.module)

  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [pathname])

  const subRoutesToExclude = [
    '/online-account/preliminary-verified/:id',
    '/online-account/maker-list/:id',
    '/online-account/preliminary-unverified/:id',
    '/online-account/new-maker-list/:id',
    '/online-account/maker-repair-list/:id',
    '/online-account/checker-list/:id',
    '/online-account/checker-repair-list/:id',
    '/online-account/pending-accounts/:id',
    '/online-account/account-open/:id',
    '/online-account/rejected-list/:id',
    '/fd-request/fd-requested/:id',
    '/fd-request/fd-rejected/:id',
    '/fd-request/fd-request-unverified/:id',
    '/fd-request/fd-approved/:id',
    '/online-account/onlineOnPrim/:id',
  ]

  const routeData = !isEmpty(module?.moduleData)
    ? module?.moduleData?.map((parentData: any) => ({
        route: parentData.alias,
        title: parentData.name,
        subRoutes: parentData?.children
          ?.filter((item: any) => {
            return !subRoutesToExclude.some((route) => item.alias?.includes(route))
          })
          ?.map((childrenData: IModuleModel) => ({
            route: childrenData.alias,
            title: childrenData.name,
          })),
      }))
    : []

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        {/* <AsideMenuMain /> */}
        <AsideMenuMain data={routeData} />
      </div>
    </div>
  )
}

export {AsideMenu}
