type Props = {
  label: string
  value: any
}

const SingleColumn = ({label, value}: Props) => {
  return (
    <div className='mb-3 col-12 col-md-6 col-lg-4'>
      <span className='form-review-field'>{label}:</span>
      {value}
    </div>
  )
}

export default SingleColumn
