import SingleColumn from "../SingleColumn";

type Props = {
    familyDetails?: any,
    isMarried?: boolean,
    spouseName?: any
}

const FamilyDetails = ({ familyDetails, isMarried, spouseName }: Props) => {
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Family Details</div>
            <div className="row">
                {/* <div className='mb-3 col-12 col-md-6 col-lg-4'> */}
                {isMarried && <SingleColumn label="Spouse Name" value={spouseName} />}
                {familyDetails?.map((element: any, index: number) => (
                    <div className="row" key={index}>
                        <SingleColumn label="Relation" value={element?.relation?.title} />
                        <SingleColumn label="First Name" value={element?.firstName} />
                        <SingleColumn label="Middle Name" value={element?.middleName} />
                        <SingleColumn label="Last Name" value={element?.lastName} />
                        <SingleColumn label="Id Type" value={element?.idType} />
                        <SingleColumn label="Id Number" value={element?.idNumber} />
                    </div>
                ))}

            </div>
            {/* </div> */}

        </>
    )
}

export default FamilyDetails;