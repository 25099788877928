import { call, put, takeLatest, delay } from "redux-saga/effects";
import { ActionModel } from "src/cms/helpers/Models/ActionModel";
import { actions } from "./actions";
import { ResponseModel } from "src/cms/helpers/Models/ResponseModel";
import { service } from "./service";
import { actionTypes } from "./constants";
import { globalActionTypes } from "src/app/modules/errors/redux/constants";
import { SortProductManagerActionModel, SortProductManagerModel } from "../Model";

function* getAllProductManagerSaga(action: ActionModel) {
    try {
        const params: any = action.payload?.params
        yield delay(500)
        const response: ResponseModel = yield call(service.getAllProductManager, params);
        const data: any = response?.data;
        yield put(actions.getAllProductManagerSuccess(data))
    } catch (error: any) {
        yield put(actions.getAllProductManagerError(error));
    }
}

function* enableProductManagerSaga(action: ActionModel) {
    try {
        const selectedUsers: any = action.payload?.data
        const response: ResponseModel = yield call(service.enableProductManager, selectedUsers)
        yield put(actions.enableProductManagerSuccess(response?.data))
        yield put(actions.enableProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.enableProductManagerFinish())
    }
}

function* disableProductManagerSaga(action: ActionModel) {
    try {
        const selectedUsers: any = action.payload?.data
        const response: ResponseModel = yield call(service.disableProductManager, selectedUsers)
        yield put(actions.disableProductManagerSuccess(response?.data))
        yield put(actions.disableProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.enableProductManagerFinish())
    }
}

function* singleEnableProductManagerSaga(action: ActionModel) {
    try {
        const selectedUsers: any = action.payload?.data
        const response: ResponseModel = yield call(service.singleEnableProductManager, selectedUsers)
        yield put(actions.singleEnableProductManagerSuccess(response?.data))
        yield put(actions.singleEnableProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.singleEnableProductManagerFinish())
    }
}

function* singleDisableProductManagerSaga(action: ActionModel) {
    try {
        const selectedUsers: any = action.payload?.data
        const response: ResponseModel = yield call(service.singleDisableProductManager, selectedUsers)
        yield put(actions.singleDisableProductManagerSuccess(response?.data))
        yield put(actions.singleDisableProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.singleDisableProductManagerFinish())
    }
}

function* deleteProductManagerSaga(action: ActionModel) {
    try {
        const body: any = action.payload
        const response: ResponseModel = yield call(service.deleteProductManager, body)
        yield put(actions.deleteProductManagerSuccess(response.data?.data))
        yield put(actions.deleteProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.deleteProductManagerFinish())
    }
}

function* addProductManagerSaga(action: ActionModel) {
    try {
        const body = action.payload
        const response: ResponseModel = yield call(service.addProductManager, body)
        yield put(actions.addProductManagerSuccess(response.data?.data));
        yield put(actions.addProductManagerFinish())
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.addProductManagerFinish())
    }
}

function* updateProductManagerSaga(action: ActionModel) {
    try {
        const body: any = action.payload;
        const response: ResponseModel = yield call(service.updateProductManager, body, action.payload?.id)
        yield put(actions.updateProductManagerSuccess(response.data?.data));
        yield put(actions.updateProductManagerFinish());
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put(actions.updateProductManagerFinish())
    }
}

function* sortProductManager(action: SortProductManagerActionModel) {
    try {
        const body: SortProductManagerModel = action.payload
        const response: ResponseModel = yield call(service.sortProductManager, body)

        yield put({
            type: actionTypes.SORT_PRODUCT_MANAGER_SUCCESS,
            payload: response?.data?.data,
        })
        yield put({ type: actionTypes.SORT_PRODUCT_MANAGER_FINISH })
    } catch (err: any) {
        if (err.response) {
            yield put({ type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message })
        }
        yield put({ type: actionTypes.SORT_PRODUCT_MANAGER_FINISH })
    }
}

export function* saga() {
    yield takeLatest(actionTypes.GET_ALL_PRODUCT_MANAGER_START, getAllProductManagerSaga);
    yield takeLatest(actionTypes.ENABLE_PRODUCT_MANAGER_REQUEST, enableProductManagerSaga)
    yield takeLatest(actionTypes.DISABLE_PRODUCT_MANAGER_REQUEST, disableProductManagerSaga)
    yield takeLatest(actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST, singleEnableProductManagerSaga)
    yield takeLatest(actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST, singleDisableProductManagerSaga)
    yield takeLatest(actionTypes.DELETE_PRODUCT_MANAGER_START, deleteProductManagerSaga)
    yield takeLatest(actionTypes.ADD_PRODUCT_MANAGER_START, addProductManagerSaga)
    yield takeLatest(actionTypes.UPDATE_PRODUCT_MANAGER_START, updateProductManagerSaga)
    yield takeLatest(actionTypes.SORT_PRODUCT_MANAGER_START, sortProductManager)
}