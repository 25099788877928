import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Detail from "src/app/modules/common/components/Preliminary/Detail";

const PreliminaryUnverifiedDetail = () => {
    const location = useLocation();
    // const history = useHistory();
    // const dispatch = useDispatch();

    const data: any = location.state;

    return (
        <Detail heading="Preliminary Unverified Information" data={data} />
    )
}

export default PreliminaryUnverifiedDetail;