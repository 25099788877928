import { Action } from "redux";
import { actionTypes } from './constants'
import { ProductManagerModel } from "../Model";

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export interface IProductManagerState {
    data?: {
        productManager: { [key: string]: number | string }[]
        meta: { [key: string]: number }
    }
    sortProductManagerData: IProductManagerState[]
    // allProductManager?: ProductManagerModel[]
    allProductManager?: { productManager: any[] | any }
    deleteSuccess?: boolean
    enableSuccess?: boolean
    disableSuccess?: boolean
    singleEnableSuccess?: boolean
    singleDisableSuccess?: boolean
    loading?: boolean
    success?: boolean
}

const initialProductManagerState: IProductManagerState = {
    data: {
        productManager: [],
        meta: {},
    },
    sortProductManagerData: [],
    allProductManager: { productManager: [] },
    deleteSuccess: false,
    enableSuccess: false,
    disableSuccess: false,
    singleEnableSuccess: false,
    singleDisableSuccess: false,
    loading: false,
    success: false,
}

export const reducer = (
    state: IProductManagerState = initialProductManagerState,
    action: ActionWithPayload<IProductManagerState>
) => {
    switch (action.type) {
        case actionTypes.GET_ALL_PRODUCT_MANAGER_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.GET_ALL_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                // allProductManager: action.payload,
                data: action.payload?.data,
                loading: false
            }
        }

        case actionTypes.GET_ALL_PRODUCT_MANAGER_FINISH: {
            const error = action.payload;
            return {
                ...state,
                error,
                success: false,
                loading: false
            }

        }

        //Enable
        case actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_REQUEST: {
            return { ...state, toggleLoading: true }
        }

        case actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_SUCCESS: {
            const changedData: any = action.payload?.data?.productManager
            let newData = state?.data?.productManager?.map((data) => {
                if (data?.id === changedData[0]?.id) {
                    return {
                        ...data,
                        status: !data?.status,
                    }
                } else {
                    return data
                }
            })
            return {
                ...state,
                data: { ...state, meta: state?.data?.meta, productManager: newData },
                singleEnableSuccess: true,
                toggleLoading: false,
            }
        }

        case actionTypes.SINGLE_ENABLE_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                singleEnableSuccess: false,
                toggleLoading: false,
            }
        }

        //Disable
        case actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_REQUEST: {
            return { ...state, toggleLoading: true }
        }

        case actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_SUCCESS: {
            const changedData: any = action.payload?.data?.productManager
            let newData = state?.data?.productManager?.map((data) => {
                if (data?.id === changedData[0]?.id) {
                    return {
                        ...data,
                        status: !data?.status,
                    }
                } else {
                    return data
                }
            })

            return {
                ...state,
                data: { ...state, meta: state?.data?.meta, productManager: newData },
                singleDisableSuccess: true,
                toggleLoading: false,
            }
        }

        case actionTypes.SINGLE_DISABLE_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                singleDisableSuccess: false,
                toggleLoading: false,
            }
        }

        //Enable
        case actionTypes.ENABLE_PRODUCT_MANAGER_REQUEST: {
            return { ...state, loading: true }
        }

        case actionTypes.ENABLE_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                enableSuccess: true,
                loading: false,
            }
        }

        case actionTypes.ENABLE_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                loading: false,
                enableSuccess: false,
            }
        }

        //disable
        case actionTypes.DISABLE_PRODUCT_MANAGER_REQUEST: {
            return { ...state, loading: true }
        }

        case actionTypes.DISABLE_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                disableSuccess: true,
                loading: false,
            }
        }

        case actionTypes.DISABLE_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                loading: false,
                disableSuccess: false,
            }
        }

        case actionTypes.DELETE_PRODUCT_MANAGER_START: {
            return { ...state, loading: true }
        }

        case actionTypes.DELETE_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                deleteSuccess: true,
                loading: false,
            }
        }

        case actionTypes.DELETE_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                loading: false,
                deleteSuccess: false,
            }
        }

        case actionTypes.ADD_PRODUCT_MANAGER_START: {
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.ADD_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                data: action?.payload?.data,
                success: true,
                loading: false
            }
        }

        case actionTypes.ADD_PRODUCT_MANAGER_FINISH: {
            const error = action.payload;
            return {
                ...state,
                error,
                loading: false
            }
        }

        case actionTypes.RESET_PRODUCT_MANAGER: {
            return {
                ...state,
                data: [],
                success: false
            }
        }

        case actionTypes.UPDATE_PRODUCT_MANAGER_START: {
            return { ...state, loading: true }
        }

        case actionTypes.UPDATE_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                data: action?.payload?.data,
                success: true,
                loading: false,
            }
        }

        case actionTypes.UPDATE_PRODUCT_MANAGER_FINISH: {
            const error = action.payload
            return { ...state, error, loading: false }
        }

        case actionTypes.SORT_PRODUCT_MANAGER_START: {
            return { ...state, loading: true }
        }

        case actionTypes.SORT_PRODUCT_MANAGER_SUCCESS: {
            return {
                ...state,
                sortProductManagerData: action?.payload,
                success: true,
                loading: false,
            }
        }

        case actionTypes.SORT_PRODUCT_MANAGER_FINISH: {
            return {
                ...state,
                loading: false,
                success: false,
            }
        }

        case actionTypes.SORT_PRODUCT_MANAGER_RESET: {
            return {
                ...state,
                loading: false,
                success: false,
                sortProductManagerData: [],
            }
        }

        default:
            return state
    }
}