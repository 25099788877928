export const actionTypes = {
  //get preliminary verified
  GET_ACCOUNT_OPEN_LIST_START: 'GET_ACCOUNT_OPEN_LIST_START',
  GET_ACCOUNT_OPEN_LIST_SUCCESS: 'GET_ACCOUNT_OPEN_LIST_SUCCESS',
  GET_ACCOUNT_OPEN_LIST_FINISH: 'GET_ACCOUNT_OPEN_LIST_FINISH',

  //get Export file
  EXPORT_ACCOUNT_OPEN_FILE_START: 'EXPORT_ACCOUNT_OPEN_FILE_START',
  EXPORT_ACCOUNT_OPEN_FILE_SUCCESS: 'EXPORT_ACCOUNT_OPEN_FILE_SUCCESS',
  EXPORT_ACCOUNT_OPEN_FILE_FINISH: 'EXPORT_ACCOUNT_OPEN_FILE_FINISH',
}
