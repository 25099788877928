import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortTenureModel, TenureModel} from '../Model/TenureModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const TENURE_API = `${API_URL}/tenure`

export const service = {
  getTenure: (params: ParamsModel) => {
    return axios.get(TENURE_API, {params})
  },
  getAllTenure: () => {
    return axios.get(`${TENURE_API}/list`)
  },

  addTenure: (data: any) => {
    return axios.post(TENURE_API, data)
  },

  updateTenure: (body: TenureModel, id: string) => {
    return axios.put(`${TENURE_API}/${id}`, body)
  },

  deleteTenure: (data: {id: string}) => {
    return axios.delete(`${TENURE_API}/delete`, {data})
  },

  enableTenure: (data: Array<string>) => {
    const selectedTenure = {
      tenureId: data,
    }
    return axios.patch(`${TENURE_API}/enable`, selectedTenure)
  },

  disableTenure: (data: Array<string>) => {
    const selectedTenure = {
      tenureId: data,
    }
    return axios.patch(`${TENURE_API}/disable`, selectedTenure)
  },

  singleEnableTenure: (data: Array<string>) => {
    const selectedTenure = {
      tenureId: [data],
    }
    return axios.patch(`${TENURE_API}/enable`, selectedTenure)
  },

  singleDisableTenure: (data: Array<string>) => {
    const selectedTenure = {
      tenureId: [data],
    }
    return axios.patch(`${TENURE_API}/disable`, selectedTenure)
  },
  sortTenure: (body: SortTenureModel) => {
    return axios.patch(`${TENURE_API}/sort`, body)
  },
}
