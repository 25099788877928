export const actionTypes = {
  // get
  GET_ALL_TERM_AND_CONDITION_START: 'GET_ALL_TERM_AND_CONDITION_START',
  GET_ALL_TERM_AND_CONDITION_SUCCESS: 'GET_ALL_TERM_AND_CONDITION_SUCCESS',
  GET_ALL_TERM_AND_CONDITION_FINISH: 'GET_ALL_TERM_AND_CONDITION_FINISH',

  // extended hours
  GET_TERM_AND_CONDITION_START: 'GET_TERM_AND_CONDITION_START',
  GET_TERM_AND_CONDITION_SUCCESS: 'GET_TERM_AND_CONDITION_SUCCESS',
  GET_TERM_AND_CONDITION_FINISH: 'GET_TERM_AND_CONDITION_FINISH',

  // add branch
  ADD_TERM_AND_CONDITION_START: 'ADD_TERM_AND_CONDITION_START',
  ADD_TERM_AND_CONDITION_SUCCESS: 'ADD_TERM_AND_CONDITION_SUCCESS',
  ADD_TERM_AND_CONDITION_FINISH: 'ADD_TERM_AND_CONDITION_FINISH',
  RESET_PROGRAM_TAG: 'RESET_PROGRAM_TAG',

  // update PROGRAM_TAG
  UPDATE_TERM_AND_CONDITION_START: 'UPDATE_TERM_AND_CONDITION_START',
  UPDATE_TERM_AND_CONDITION_SUCCESS: 'UPDATE_TERM_AND_CONDITION_SUCCESS',
  UPDATE_TERM_AND_CONDITION_FINISH: 'UPDATE_TERM_AND_CONDITION_FINISH',

  // delete PROGRAM_TAG
  DELETE_TERM_AND_CONDITION_START: 'DELETE_TERM_AND_CONDITION_START',
  DELETE_TERM_AND_CONDITION_SUCCESS: 'DELETE_TERM_AND_CONDITION_SUCCESS',
  DELETE_TERM_AND_CONDITION_FINISH: 'DELETE_TERM_AND_CONDITION_FINISH',

  // Enable PROGRAM_TAG
  ENABLE_TERM_AND_CONDITION_REQUEST: 'ENABLE_TERM_AND_CONDITION_REQUEST',
  ENABLE_TERM_AND_CONDITION_SUCCESS: 'ENABLE_TERM_AND_CONDITION_SUCCESS',
  ENABLE_TERM_AND_CONDITION_FINISH: 'ENABLE_TERM_AND_CONDITION_FINISH',

  // Disable PROGRAM_TAG
  DISABLE_TERM_AND_CONDITION_REQUEST: 'DISABLE_TERM_AND_CONDITION_REQUEST',
  DISABLE_TERM_AND_CONDITION_SUCCESS: 'DISABLE_TERM_AND_CONDITION_SUCCESS',
  DISABLE_TERM_AND_CONDITION_FINISH: 'DISABLE_TERM_AND_CONDITION_FINISH',

  // Enable PROGRAM_TAG
  SINGLE_ENABLE_TERM_AND_CONDITION_REQUEST: 'SINGLE_ENABLE_TERM_AND_CONDITION_REQUEST',
  SINGLE_ENABLE_TERM_AND_CONDITION_SUCCESS: 'SINGLE_ENABLE_TERM_AND_CONDITION_SUCCESS',
  SINGLE_ENABLE_TERM_AND_CONDITION_FINISH: 'SINGLE_ENABLE_TERM_AND_CONDITION_FINISH',

  // Disable PROGRAM_TAG
  SINGLE_DISABLE_TERM_AND_CONDITION_REQUEST: 'SINGLE_DISABLE_TERM_AND_CONDITION_REQUEST',
  SINGLE_DISABLE_TERM_AND_CONDITION_SUCCESS: 'SINGLE_DISABLE_TERM_AND_CONDITION_SUCCESS',
  SINGLE_DISABLE_TERM_AND_CONDITION_FINISH: 'SINGLE_DISABLE_TERM_AND_CONDITION_FINISH',

  // sort
  SORT_TERM_AND_CONDITION_START: 'SORT_TERM_AND_CONDITION_START',
  SORT_TERM_AND_CONDITION_SUCCESS: 'SORT_TERM_AND_CONDITION_SUCCESS',
  SORT_TERM_AND_CONDITION_FINISH: 'SORT_TERM_AND_CONDITION_FINISH',
  SORT_TERM_AND_CONDITION_RESET: 'SORT_TERM_AND_CONDITION_RESET',
}
