import SingleColumn from "./SingleColumn";
// type CustomerDetail = {
//     id?: string,
//     customerId?: string,
//     product?: string,
//     accountType?: object | Array<any>,
//     salutation?: object | Array<any>
// }

type Props = {
    heading: string,
    data?: any
}

const PreliminaryDetail = ({ heading, data }: Props) => {
    return (
        <>
            <div className="bg-white p-4" style={{ background: 'ECF6FB' }}>
                <div className="mb-0">
                    <h3 className='ekyc-title formHeading mb-3'>{heading}</h3>
                </div>
                <div className="row">
                    <SingleColumn label="Customer Id" value={data?.customerId} />
                    <SingleColumn label="Applying For Product" value={data?.product?.title} />
                    <SingleColumn label="Account Type" value={data?.accountType?.title} />
                    <SingleColumn label="Salutation" value={data?.salutation?.title} />
                    <SingleColumn label="First Name" value={data?.firstName} />
                    <SingleColumn label="Middle Name" value={data?.middleName} />
                    <SingleColumn label="Last Name" value={data?.lastName} />
                    <SingleColumn label="Gender" value={data?.gender?.title} />
                    <SingleColumn label="Email" value={data?.email} />
                    <SingleColumn label="Phone Number" value={data?.phoneNumber} />
                    <SingleColumn label="Mobile Number" value={data?.mobileNumber} />
                    <SingleColumn label="Date of birth (B.S)" value={data?.dobBs} />
                    <SingleColumn label="Date of birth (A.D)" value={data?.dobAd} />
                    <SingleColumn label="Applying From" value={data?.applyFrom === "1" ? 'Inside Country' : 'Outside Country'} />
                    {data?.applyFrom === "0" && <SingleColumn label="Country" value={data?.country?.title} />}
                    <SingleColumn label="Currency" value={data?.currency?.title} />
                    <SingleColumn label="isNrn" value={data?.isNrn === '1' ? "Yes" : "No"} />
                    <SingleColumn label="Other Contact Medium" value={data?.otherContactMedium} />
                    <SingleColumn label="Other Contact Detail" value={data?.otherContactDetail} />
                </div>
            </div>
        </>
    )
}

export default PreliminaryDetail;