import { ParamsModel } from "../Model/index";
import { actionTypes } from "./constants";
import { PreVerifiedState } from "./reducer";

export const actions = {
    getAllPreliminaryVerified: (params: ParamsModel = { page: 1, limit: 10 }) => {
        return {
            type: actionTypes.GET_PRELIMINARY_VERIFIED_START,
            payload: { params }
        }
    },

    getAllPreliminaryVerifiedSuccess: (data: PreVerifiedState) => ({
        type: actionTypes.GET_PRELIMINARY_VERIFIED_SUCCESS,
        payload: data
    }),

    getAllPreliminaryVerifiedError: (data: PreVerifiedState) => ({
        type: actionTypes.GET_PRELIMINARY_VERIFIED_FINISH,
        payload: data
    }),

    //export file
    exportFile: (fileName: string, params: ParamsModel) => {
        return {
            type: actionTypes.EXPORT_VERIFIED_FILE_START,
            payload: { fileName, params }
        }
    },

    exportFileSuccess: (data: any) => ({
        type: actionTypes.EXPORT_VERIFIED_FILE_SUCCESS,
        payload: data,
    }),

    exportFileError: () => ({
        type: actionTypes.EXPORT_VERIFIED_FILE_FINISH,
    }),

    //resend link
    resendLink: (customerId: string) => ({
        type: actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_START,
        payload: {
            customerId
        }
    }),

    resendLinkSuccess: () => ({
        type: actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_SUCCESS
    }),

    resendLinkFinish: () => ({
        type: actionTypes.RESEND_PRELIMINARY_VERIFIED_LINK_FINISH
    })
}
