import { useEffect, useState } from "react"
import SingleColumn from "../SingleColumn"

type Props = {
    occupationDetail?: any
}

const Occupation = ({ occupationDetail }: Props) => {
    const [displayOtherOccupation, setDisplayOtherOccupation] = useState(false);

    useEffect(() => {
        if (occupationDetail?.occupation?.title === "OTHER,PLEASE SPECIFY") {
            setDisplayOtherOccupation(true);
        }
    }, [occupationDetail])
    return (
        <>
            <div className="mb-1 text-primary fs-5 fw-bold">Occupation Details</div>
            <div className="row">
                {/* <div className='mb-3 col-12 col-md-6 col-lg-4'> */}
                <SingleColumn label="Occupation" value={occupationDetail?.occupation?.title} />
                {displayOtherOccupation && <SingleColumn label="Other Occupation" value={occupationDetail?.occupationOther} />}
                <SingleColumn label="Source of Income" value={occupationDetail?.sourceIncome?.title} />
            </div>
            {/* </div> */}
        </>
    )
}

export default Occupation