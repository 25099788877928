import {ErrorMessage, Form, Formik} from 'formik'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Divider, Table} from 'rsuite'
import * as newMakerListRedux from 'src/app/modules/onlineAccount/newMakerList/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as yup from 'yup'
import {StateParamsModel} from '../../Model'
import IdentityInformation from './IdentityInformation'
import OtherInformation from './OtherInformation'
import PersonalInformation from './PersonalInformation'
import ProductInformation from './ProductInformation'
import ProfessionalInformation from './ProfessionalInformation'
import ReactToPrint from 'react-to-print'
import {Button} from 'react-bootstrap-v5'
import {FaRegCheckCircle} from 'react-icons/fa'

import * as onlineOnPrimRedux from '../../../onlineAccount/onlineOnPrim/redux/index'
import ImagePreview from './ImagePreview'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  displayChangeStatus?: boolean
  options?: Array<any>
  displayAccountNumberField?: boolean
  displayPreviewPdf?: boolean
  fileUpload?: boolean
}

const validationSchemaStatus = yup.object().shape({
  status: yup.string().required('Status is required'),
  remarks: yup.string().max(1000, 'Remarks should be less than 1000 character'),
})

const validationSchemaAccountNumber = yup.object().shape({
  accountNumber: yup
    .string()
    .matches(/^\d{20}$/, 'Account Number must be a 20 digit number')
    .required('Account Number is required'),
  remarks: yup.string().max(1000, 'Remarks should be less than 1000 character'),
})

const AccountOpeningDetail = ({
  displayChangeStatus,
  options,
  displayAccountNumberField,
  displayPreviewPdf,
  fileUpload,
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  let pdfContainerRef = useRef(null)
  const fileUploadRef = useRef<HTMLInputElement>(null)

  const data: any = location.state

  const Cell = Table.Cell

  const [params, setParams] = useState<StateParamsModel>({
    customerId: data?.customerId,
    page: 1,
    limit: 10,
  })
  const [fileTypeId, setFileTypeId] = useState<string>()
  const [fileUploadErrorFlag, setFileUploadErrorFlag] = useState<boolean>()
  const [aofURL, setAofURL] = useState<string>()

  const {
    data: {master: fileTypes},
  } = useSelector((state: any) => {
    return state?.fileTypes
  })

  const {
    user: {
      data: {name},
    },
  } = useSelector((state: any) => state.auth)

  const {uploadSuccess} = useSelector((state: any) => state.onlineOnPrim)

  useEffect(() => {
    if (fileTypes) {
      const onPrimFileTypeId = fileTypes?.find((fileType: any) => fileType?.code === 'cms')?.id
      setFileTypeId(onPrimFileTypeId)
    }
  }, [fileTypes])

  useEffect(() => {
    dispatch(onlineOnPrimRedux.actions.getFileTypes({table: 'file_types'}))
  }, [])

  const {
    logData: logList,
    loading,
    formSuccess,
  }: any = useSelector((state: any) => {
    return state?.newMakerList
  })

  useEffect(() => {
    dispatch(newMakerListRedux.actions.getAllLog(params))
  }, [])

  const excludeLogStatus = logList?.logs?.filter((item: any) => {
    const excludedStatusList = [
      'Other Completed',
      'Identity Completed',
      'Professional Completed',
      'Personal Completed',
      ,
    ]
    return !excludedStatusList.includes(item.previousAccountStatus)
  })

  const logDataSource =
    excludeLogStatus?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      currentAccountStatus: item?.currentAccountStatus || '-',
      // currentEkycStatus: item?.currentEkycStatus || '-',
      date: moment(item?.date).format('MMMM Do YYYY, h:mm:ss A') || '-',
      remarks: item?.remarks || '-',
    })) ?? []

  const uploadDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (fileTypeId) {
        dispatch(
          onlineOnPrimRedux.actions.uploadOnprimDocument({
            customerId: data?.customerId,
            fileTypeId,
            file: e.target.files[0],
          })
        )
        setFileUploadErrorFlag(false)
      }
    }
  }
  // useEffect(() => {

  // }, [])

  const logColumns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
      sortable: false,
    },

    {
      label: 'Date',
      dataKey: 'date',
      // flexGrow: 1,
      cell: <Cell dataKey='date' />,
      sortable: false,
      width: 490,
    },

    {
      label: 'Ekyc Status',
      dataKey: 'currentAccountStatus',
      // flexGrow: 1,
      cell: <Cell dataKey='currentAccountStatus' />,
      sortable: false,
      width: 490,
    },

    {
      label: 'Remarks',
      dataKey: 'remarks',
      width: 490,
      cell: <Cell dataKey='remarks' />,
      sortable: false,
    },
  ]

  useEffect(() => {
    const file = data?.documentUpload?.document?.find(
      (element: any) =>
        element?.fileType?.code === 'cms' &&
        element?.fileType?.document_type === 'Account Opening Info' &&
        element?.fileType?.title === 'AOF'
    )?.file
    setAofURL(file)
  }, [])

  useEffect(() => {
    if (formSuccess) {
      toast.success('Status Updated Successfully')
      history.goBack()
    }
  }, [formSuccess])

  return (
    <>
      {displayPreviewPdf && (
        <div className='d-flex justify-content-end'>
          <ReactToPrint
            content={() => pdfContainerRef.current}
            trigger={() => <Button type='primary'>Preview PDF</Button>}
            bodyClass='m-4 container'
            pageStyle='{{@page{size: auto }}}'
            documentTitle={`OnlineOnPrim_${data?.customerId}`}
          />
        </div>
      )}
      <div ref={pdfContainerRef}>
        <div className='print-logged-user'>Logged User: {name}</div>
        <div className='mb-1 fs-5 fw-bold'>
          <h3 className='ekyc-title formHeading mb-3'>Account Type: {data?.accountType?.title}</h3>
        </div>
        <div className='mb-1 fs-5 fw-bold'>
          <h3 className='ekyc-title formHeading mb-3'>
            Applying From:{' '}
            {data?.applyFrom === '0'
              ? 'Outside the Country'
              : data?.applyFrom === '1'
              ? 'Inside the Country'
              : 'N/a'}
          </h3>
        </div>
        <ProductInformation data={data?.product} />
        <PersonalInformation data={data} documents={data?.documentUpload?.document} />
        <ProfessionalInformation data={data} documents={data?.documentUpload?.document} />
        <IdentityInformation
          accountType={data?.accountType?.cbs_code}
          identificationInformation={
            data?.identityInformationCollection?.identityInformationData?.[0]
          }
          guardianIdentificationInformation={data?.guardianIdentityInformationData}
          documents={data?.documentUpload?.document}
        />
        <OtherInformation
          otherInformation={data?.otherInformationCollection?.otherInformationData?.[0]}
          nomineeInformation={data?.nomineeInformationCollection?.nomineeInformationData}
          documents={data?.documentUpload?.document}
        />
        {displayPreviewPdf && (
          <div className='row mt-10'>
            <div className='col-md-3 col-4'>
              <hr />
              <p className='fs-3 text-center'>Signature</p>
            </div>
          </div>
        )}
      </div>
      {aofURL && (
        <div className='my-2'>
          <label className='fw-bolder'>AOF: </label>{' '}
          <button
            onClick={(e) => {
              if (aofURL) {
                e.preventDefault()
                window.open(`${imageBaseUrl}/${aofURL}`)
              }
              // window.open(`${imageBaseUrl}/${imageSrc}`)
            }}
          >
            View
          </button>
        </div>
      )}
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Log Information</h3>
      </div>
      {logDataSource?.length > 0 && (
        <>
          <div className='datatable'>
            <RSuiteTable
              showCheckbox={false}
              columns={logColumns}
              data={logDataSource}
              // checkedValues={selectedData}
              showLoading={logList?.loading}
            />
            {/* <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='sm'
              layout={['total', '-', 'limit', '|', 'pager', 'skip']}
              total={logList?.data?.meta?.total || 0}
              limitOptions={[10, 20, 50, 100]}
              limit={params?.limit}
              activePage={params?.page}
              onChangePage={(value) => setParams({...params, page: value})}
              onChangeLimit={handleChangeLogLimit}
            /> */}
          </div>
          <Divider />
        </>
      )}

      {displayChangeStatus && (
        <>
          <div className='mb-0 mt-4'>
            <h3 className='ekyc-title formHeading mb-3'>Status & Remarks</h3>
          </div>
          <Formik
            initialValues={{remarks: '', status: '', accountNumber: ''}}
            validationSchema={
              displayAccountNumberField ? validationSchemaAccountNumber : validationSchemaStatus
            }
            onSubmit={(values, {setSubmitting}) => {
              if (fileUpload && values.status == 'Accept') {
                if (!fileUploadRef?.current?.value) {
                  setFileUploadErrorFlag(true)
                  setSubmitting(false)
                  return
                }
              }
              const formData = {
                customerId: data?.customerId,
                status: values?.status,
                accountNumber: displayAccountNumberField ? values.accountNumber : '',
                accountOpeningStatus: data?.status?.current_account_status,
                remarks: values?.remarks,
              }

              dispatch(newMakerListRedux.actions.addRemarks(formData))
            }}
          >
            {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
              return (
                <Form>
                  <div className='card-body border-top'>
                    <div className='row'>
                      {displayAccountNumberField ? (
                        <div className='col-md-7 col-sm-7 col-sm-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Account Number'
                            label='Account Number'
                            name='accountNumber'
                            onChange={handleChange}
                            required
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      ) : (
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            type='text'
                            labelClassName='col-md-12'
                            placeholder='Select status'
                            label='Status'
                            name='status'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            isClearable={true}
                            options={options}
                            required={displayAccountNumberField ? false : true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      )}
                      {fileUpload && (
                        <div className='col-12 my-3'>
                          <div className='form-group col-6'>
                            <label className='mb-1 fw-bolder' htmlFor='onPrimDocument'>
                              File Upload
                            </label>
                            <input
                              type='file'
                              onChange={uploadDocument}
                              className='inputFile form-control border'
                              id='onPrimDocument'
                              ref={fileUploadRef}
                            />
                            {fileUploadErrorFlag && (
                              <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                                <p className='field-error-message'>File is required</p>
                              </div>
                            )}
                          </div>
                          {uploadSuccess && <FaRegCheckCircle size={20} />}
                        </div>
                      )}

                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          as='textarea'
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          placeholder='Remarks'
                          label='Remarks'
                          name='remarks'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      {/* <div className="fv-plugins-message-container text-danger fw-bolder small text-end mt-1">
                                                    <ErrorMessage
                                                        name="status"
                                                        component="div"
                                                        className="field-error-message"
                                                    />
                                                </div> */}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        disabled={loading || errors?.status ? true : false}
                        className='btn btn-primary btn-sm ms-3'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )
}

export default AccountOpeningDetail
