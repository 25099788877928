export const actionTypes = {
  //get preliminary verified
  GET_ONLINEONPRIM_LIST_START: 'GET_ONLINEONPRIM_LIST_START',
  GET_ONLINEONPRIM_LIST_SUCCESS: 'GET_ONLINEONPRIM_LIST_SUCCESS',
  GET_ONLINEONPRIM_LIST_FINISH: 'GET_ONLINEONPRIM_LIST_FINISH',

  //get Export file
  EXPORT_ONLINEONPRIM_FILE_START: 'EXPORT_ONLINEONPRIM_FILE_START',
  EXPORT_ONLINEONPRIM_FILE_SUCCESS: 'EXPORT_ONLINEONPRIM_FILE_SUCCESS',
  EXPORT_ONLINEONPRIM_FILE_FINISH: 'EXPORT_ONLINEONPRIM_FILE_FINISH',

  // file types
  GET_FILE_TYPES_START: 'GET_FILE_TYPES_START',
  GET_FILE_TYPES_SUCCESS: 'GET_FILE_TYPES_SUCCESS',
  GET_FILE_TYPES_FINISH: 'GET_FILE_TYPES_FINISH',

  //upload onprim file
  STORE_ONPRIM_DOCUMENT_UPLOAD_START: 'STORE_ONPRIM_DOCUMENT_UPLOAD_START',
  STORE_ONPRIM_DOCUMENT_UPLOAD_SUCCESS: 'STORE_ONPRIM_DOCUMENT_UPLOAD_SUCESS',
  STORE_ONPRIM_DOCUMENT_UPLOAD_FINISH: 'STORE_ONPRIM_DOCUMENT_UPLOAD_FINISH',
}
