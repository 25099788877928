import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_CHECKER_REPAIR_LIST = `${API_URL}/customer-data`
export const EXPORT_MAKER_REPAIR_LIST = `${API_URL}/repair-list/export`

export const service = {
  getCheckerRepairList: (params: ParamsModel) => {
    return axios.get(GET_CHECKER_REPAIR_LIST, {params})
  },

  exportMakerRepairListFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${EXPORT_MAKER_REPAIR_LIST}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
