import parse from 'html-react-parser'
import React, {useEffect, useState} from 'react'
import ImagePreview from '../ImagePreview'
import SingleColumn from '../SingleColumn'

const ProductInformation = ({data}: {data: any}) => {
  return (
    <div>
      <div className='mb-1 fs-5 fw-bold'>
        <h3 className='ekyc-title formHeading mb-3'>Product Information</h3>
        <div className='row'>
          <div className='mb-1 text-primary fs-5 fw-bold'>Title: {data?.title}</div>
          <SingleColumn label='Created at' value={data?.created_at} />
          <SingleColumn label='Minimum Balance' value={data?.minimum_balance} />
          <SingleColumn label='Payment Frequency' value={data?.payment_frequency} />
        </div>
      </div>
    </div>
  )
}

export default ProductInformation
