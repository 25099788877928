import AccountOpeningDetail from 'src/app/modules/common/components/AccountOpening'

const MakerRepairListAccountDetail = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <AccountOpeningDetail
        displayChangeStatus={true}
        options={[{value: 'Reject', label: 'Reject'}]}
      />
    </div>
  )
}

export default MakerRepairListAccountDetail
