import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {FDUpdateStatus} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_FD_REQUEST_LIST = `${API_URL}/fd-customer-data`
export const GET_FD_REQUEST_EXPORT = `${API_URL}/fd-customer-data/dataExport`
export const UPDATE_FD_STATUS = `${API_URL}/fd-customer-data/update-status`
export const GET_FD_LOGLIST = `${API_URL}/fd-customer-data/logs`

export const service = {
  getFDRequestList: (params: ParamsModel) => {
    return axios.get(GET_FD_REQUEST_LIST, {params})
  },

  getLogList: (params: ParamsModel) => {
    return axios.get(GET_FD_LOGLIST, {params})
  },

  updateStatus: (data: FDUpdateStatus) => {
    return axios.post(UPDATE_FD_STATUS, data)
  },

  exportFDRequestList: (fileName: string, params: ParamsModel) => {
    return axios.get(`${GET_FD_REQUEST_EXPORT}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
