import BankingProducts from './bankingProducts'
import NomineeDetail from './nominee'
import NomineeList from './nomineeList'
import RequiredService from './requiredService'
import SelfDeclarations from './selfDeclarations'
import UserLocation from './userLocation'

type Props = {
  otherInformation?: any
  nomineeInformation?: any
  documents?: any
}

const OtherInformation = ({otherInformation, nomineeInformation, documents}: Props) => {
  return (
    <div>
      <div className='mb-0 mt-4'>
        <h3 className='ekyc-title formHeading mb-3'>Other Information</h3>
      </div>
      <UserLocation data={otherInformation} />
      <NomineeList
        hasNominee={otherInformation?.nominee === '1' ? true : false}
        nominee={nomineeInformation}
        documents={documents}
      />

      {/* <NomineeDetail
                // hasNominee={otherInformation?.nominee === "1" ? true : false}
                nomineeData={}
            /> */}
      <BankingProducts data={otherInformation} />
      <RequiredService data={otherInformation} />
      <SelfDeclarations data={otherInformation} />
    </div>
  )
}

export default OtherInformation
