export const imageBaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL

export const topNavStatus = window.__RUNTIME_CONFIG__.REACT_TOP_MENU_NAV

export const careerValidation = window.__RUNTIME_CONFIG__.REACT_CAREER_VALIDATION

export const sliderAvailability = window.__RUNTIME_CONFIG__.REACT_SLIDER_AVAILABILITY

export const bankName = window.__RUNTIME_CONFIG__.REACT_BANK_NAME

export const showCategory = window.__RUNTIME_CONFIG__.REACT_SHOW_CATEGORY

export const onlineBaseUrl = window.__RUNTIME_CONFIG__.REACT_ONLINE_BASE_URL

