import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {UpdateAccountOpeningStatus} from '../Model'
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_NEW_MAKERLIST = `${API_URL}/customer-data`
export const UPDATE_STATUS = `${API_URL}/customer-data/update-status`
export const GET_LOGLIST = `${API_URL}/customer-data/logs`
export const EXPORT_MAKERLIST = `${API_URL}/account-opening/export`

export const service = {
  getNewMakerList: (params: ParamsModel) => {
    return axios.get(GET_NEW_MAKERLIST, {params})
  },

  getLogList: (params: ParamsModel) => {
    return axios.get(GET_LOGLIST, {params})
  },

  updateStatus: (data: UpdateAccountOpeningStatus) => {
    return axios.post(UPDATE_STATUS, data)
  },

  exportPreliminaryVerifiedFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${EXPORT_MAKERLIST}`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
