// import { ParamsModel } from "../Model/index";
import { ParamsModel } from "src/app/modules/common/Model";
import { actionTypes } from "./constants";
import { AccountRejectedState } from "./reducer";

export const actions = {
    getAllRejectedList: (params: ParamsModel = { page: 1, limit: 10 }) => {
        return {
            type: actionTypes.GET_ACCOUNT_REJECTED_LIST_START,
            payload: { params }
        }
    },

    getAllRejectedListSuccess: (data: AccountRejectedState) => ({
        type: actionTypes.GET_ACCOUNT_REJECTED_LIST_SUCCESS,
        payload: data
    }),

    getAllRejectedListError: (data: AccountRejectedState) => ({
        type: actionTypes.GET_ACCOUNT_REJECTED_LIST_FINISH,
        payload: data
    }),

    //export file
    exportFile: (fileName: string, params: ParamsModel) => {
        return {
            type: actionTypes.EXPORT_REJECTED_FILE_START,
            payload: { fileName, params }
        }
    },

    exportFileSuccess: (data: any) => ({
        type: actionTypes.EXPORT_REJECTED_FILE_SUCCESS,
        payload: data,
    }),

    exportFileError: () => ({
        type: actionTypes.EXPORT_REJECTED_FILE_FINISH,
    }),
}
