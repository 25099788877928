import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useState} from 'react'
import axios from 'axios'
import {FiX} from 'react-icons/fi'
import {isEmpty} from 'lodash'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export function AsideMenuMain({data}: any) {
  const intl = useIntl()

  const [searchVal, setSearchVal] = useState('')
  //for debounce
  const [timer, setTimer]: any = useState()
  const [searchedModules, setSearchedModules]: any = useState([])
  const [isSearching, setIsSearching] = useState(false)

  const getSearchedModules = (searchString: string) => {
    axios.get(`${API_URL}/get-dashboard-module?search=${searchString}`).then((res) => {
      const updatedData = []

      for (let data of res.data?.data?.module) {
        const routeData: any = {
          title: data?.name,
          route: data?.alias,
          subRoutes: [],
        }
        const subRoutes = []
        for (let subRoute of data?.children) {
          subRoutes.push({title: subRoute?.name, route: subRoute?.alias})
        }

        routeData.subRoutes = subRoutes
        updatedData.push(routeData)
      }

      setIsSearching(false)
      setSearchedModules(updatedData)
    })
  }

  const onSearchValChange = (value: string) => {
    setIsSearching(true)
    clearTimeout(timer)
    const newTimer = setTimeout(() => getSearchedModules(value), 500)
    setTimer(newTimer)
    setSearchVal(value)
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem to='/profile' title={'Profile'} fontIcon='bi-app-indicator' />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='position-relative  col-md-10 mx-auto'>
        <input
          type='text'
          placeholder='Search menu'
          className='form-control rounded border border-dark shadow-none'
          value={searchVal}
          onChange={(e) => onSearchValChange(e.target.value)}
        />
        {searchVal && (
          <button
            className='bg-transparent text-bl position-absolute'
            style={{right: '10px', top: '12px'}}
            onClick={() => onSearchValChange('')}
          >
            <FiX size={19} />
          </button>
        )}
      </div>
      <div className='menu-item'>
        <div className='menu-content py-5'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>

      {isSearching && searchVal ? (
        <p className='text-white text-center'>Searching...</p>
      ) : !isEmpty(searchVal ? searchedModules : data) ? (
        (searchVal ? searchedModules : data).map((routes: any, i: number) => {
          const hasChild = routes.subRoutes ? true : false
          return (
            <AsideMenuItemWithSub to={routes.route} title={routes.title} hasBullet={true} key={i}>
              {hasChild
                ? routes.subRoutes.map((subRoutes: any, j: number) => {
                    return (
                      <AsideMenuItem
                        to={subRoutes.route}
                        title={subRoutes.title}
                        hasBullet={true}
                        key={j}
                      />
                    )
                  })
                : null}
            </AsideMenuItemWithSub>
          )
        })
      ) : (
        searchVal && <p className='text-white text-center'>Menu not found!</p>
      )}
    </>
  )
}
