export const actionTypes = {
    //get preliminary verified
    GET_PRELIMINARY_VERIFIED_START: 'GET_PRELIMINARY_VERIFIED_START',
    GET_PRELIMINARY_VERIFIED_SUCCESS: 'GET_PRELIMINARY_VERIFIED_SUCCESS',
    GET_PRELIMINARY_VERIFIED_FINISH: 'GET_PRELIMINARY_VERIFIED_FINISH',

    //get Export file
    EXPORT_VERIFIED_FILE_START: 'EXPORT_VERIFIED_FILE_START',
    EXPORT_VERIFIED_FILE_SUCCESS: 'EXPORT_VERIFIED_FILE_SUCCESS',
    EXPORT_VERIFIED_FILE_FINISH: 'EXPORT_VERIFIED_FILE_FINISH',

    //resend link
    RESEND_PRELIMINARY_VERIFIED_LINK_START: 'RESEND_PRELIMINARY_VERIFIED_LINK_START',
    RESEND_PRELIMINARY_VERIFIED_LINK_SUCCESS: 'RESEND_PRELIMINARY_VERIFIED_LINK_SUCSESS',
    RESEND_PRELIMINARY_VERIFIED_LINK_FINISH: 'RESEND_PRELIMINARY_VERIFIED_LINK_FINISH',
}