import AccountOpeningDetail from 'src/app/modules/common/components/AccountOpening'
import FDAccountDetails from 'src/app/modules/common/components/FDInformation'

const FdAccountOpeningApproveList = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <FDAccountDetails displayChangeStatus={false} displayDealAccountNumberField={true} />
    </div>
  )
}

export default FdAccountOpeningApproveList
