export const actionTypes = {
  //get FD_REQUEST verified
  GET_FD_REQUEST_UNVERIFIED_START: 'GET_FD_REQUEST_UNVERIFIED_START',
  GET_FD_REQUEST_UNVERIFIED_SUCCESS: 'GET_FD_REQUEST_UNVERIFIED_SUCCESS',
  GET_FD_REQUEST_UNVERIFIED_FINISH: 'GET_FD_REQUEST_UNVERIFIED_FINISH',

  //get Export file
  EXPORT_FD_UNVERIFIED_FILE_START: 'EXPORT_FD_UNVERIFIED_FILE_START',
  EXPORT_FD_UNVERIFIED_FILE_SUCCESS: 'EXPORT_FD_UNVERIFIED_FILE_SUCCESS',
  EXPORT_FD_UNVERIFIED_FILE_FINISH: 'EXPORT_FD_UNVERIFIED_FILE_FINISH',

  //resend otp code
  RESEND_FD_REQUEST_OTP_CODE_START: 'RESEND_FD_REQUEST_OTP_CODE_START',
  RESEND_FD_REQUEST_OTP_CODE_SUCCESS: 'RESEND_FD_REQUEST_OTP_CODE_SUCCESS',
  RESEND_FD_REQUEST_OTP_CODE_FINISH: 'RESEND_FD_REQUEST_OTP_CODE_FINISH',
}
