import {CheckboxGroup} from 'rsuite'
import AccountOpeningDetail from 'src/app/modules/common/components/AccountOpening'

const CheckerListAccountDetail = () => {
  return (
    <div className='bg-white p-4' style={{background: 'ECF6FB'}}>
      <AccountOpeningDetail
        displayChangeStatus={true}
        options={[
          {value: 'Accept', label: 'Accept'},
          {value: 'Repair', label: 'Repair'},
          {value: 'Reject', label: 'Reject'},
        ]}
      />
    </div>
  )
}

export default CheckerListAccountDetail
