import axios from "axios";
import { ParamsModel } from "src/app/modules/common/Model";
// import { ParamsModel } from "src/app/modules/onlineAccount/preliminaryVerified/Model";
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_PRELIMINARY_UNVERIFIED = `${API_URL}/preliminary-unverified`;
export const GET_PRELIMINARY_UNVERIFIED_EXPORT = `${API_URL}/preliminary-unverified/export`;
export const GET_PRELIMINARY_UNVERIFIED_DETAIL = `${API_URL}/preliminary-unverified`;
export const RESEND_OTP = `${API_URL}/resend-otp`;

export const service = {
    getPreliminaryVerified: (params: ParamsModel) => {
        return axios.get(GET_PRELIMINARY_UNVERIFIED, { params })
    },

    exportPreliminaryVerifiedFile: (fileName: string, params: ParamsModel) => {
        return axios.get(`${GET_PRELIMINARY_UNVERIFIED_EXPORT}`, { params }).then(response => {
            const blob = new Blob([response?.data])
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.csv`) //or any other extension
            document.body.appendChild(link)
            link.click()
        })
    },

    getPreliminaryUnverifiedDetail: (customerId: string) => {
        return axios.get(`${GET_PRELIMINARY_UNVERIFIED_DETAIL}/${customerId}`)
    },

    resendOTP: (customerId: string) => {
        return axios.get(`${RESEND_OTP}`, {
            params: {
                customerId
            }
        })
    }
}